import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { DefaultService, PaymentMethodReportDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-daily-payment-method-report',
  templateUrl: './daily-payment-method-report.component.html',
  styleUrls: ['./daily-payment-method-report.component.scss'],
})
export class DailyPaymentMethodReportComponent {
  @Input() hideInput = false;
  paymentMethodReport?: PaymentMethodReportDTO[];
  errorMessage = false;
  @Input() from = new FormControl<Date | undefined | string>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  @Input() to = new FormControl<Date | undefined | string>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.getPaymentMethodReport();

    this.from.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.to.value) {
        this.to.setValue(undefined, {
          emitEvent: false,
        });
      }
      this.from.value && this.to?.value && this.getPaymentMethodReport();
    });

    this.to?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => this.from.value && this.to?.value && this.getPaymentMethodReport()
      );
  }

  getPaymentMethodReport(): void {
    this.from.value &&
      this.to.value &&
      this.defaultService
        .getPaymentMethodReport(
          dayjs(this.from.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate(),
          dayjs(this.to.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .add(1, 'day')
            .toDate()
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (value) => {
            this.paymentMethodReport = value;
            this.errorMessage = false;
          },
          error: (error) => {
            console.error('Error fetching payment method report: ', error);
            this.errorMessage = true;
          },
        });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    if (this.from.value && this.to.value)
      this.exportService.getReportPaymentMethods(
        dayjs(this.from.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.to.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate()
      );
  }

  getTotalByType(
    type: 'payments' | 'refunds' | 'total' | 'netto' | 'brutto' | 'vat'
  ): number {
    return (
      this.paymentMethodReport
        ?.map((t) => t[type])
        .reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0
    );
  }
}
