import { PartnerDTO } from 'parking-sdk';

export class SetCurrentPartner {
  static readonly type = '[Partner] Set current partner';
  constructor(public partner: PartnerDTO) {}
}

export class ResetPartnerState {
    static readonly type = "[Partner] Reset partner state";
    constructor() { }
  }
