import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { UserDTO } from 'parking-sdk';
import { Store } from '@ngxs/store';
import { UsersState } from '../states/users/users.state';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService {
  private loggedInUser?: UserDTO;

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router,
    store: Store
  ) {
    store.select(UsersState.loggedInUser).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const isAuthenticated = this.authenticationService.isAuthenticated();

    /* If path is partnerportal and user is of role partner */
    if (next.routeConfig?.path === 'partnerportalen' && isAuthenticated) {
      if (this.isPartnerOrAdmin()) return true;
      else return false;
    } else if (next.routeConfig?.path !== '/logga-in' && isAuthenticated) {
      /* If path is not partnerportal and not logga-in, navigate to  */
      if (this.isPartner()) {
        this.router.navigate([`/partnerportalen`]);
        return false;
      }
    }

    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/logga-in']);
      return false;
    }
  }

  isPartner(): boolean {
    return (
      this.loggedInUser?.roles?.some((role) => role.name === 'ROLE_PARTNER') ||
      false
    );
  }

  isPartnerOrAdmin(): boolean {
    return (
      this.loggedInUser?.roles?.some(
        (role) => role.name === 'ROLE_PARTNER' || role.name === 'ROLE_ADMIN'
      ) || false
    );
  }
}
