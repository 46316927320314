<div mat-dialog-content>
  <h3>{{editUser ? 'Redigera användare' : 'Skapa användare'}}</h3>
  <form [formGroup]="editUserForm" *ngIf="editUserForm">
    <mat-form-field>
      <mat-label>Användarnamn</mat-label>
      <input matInput formControlName="username" spellcheck="false" autocomplete="off">
      <mat-error>Användarnamn måste vara e-postadress</mat-error>
    </mat-form-field>

    <mat-form-field> 
      <mat-label>Roller</mat-label>
      <mat-select formControlName="roles" [compareWith]="compareSelected" autocomplete="off" multiple>
        <ng-container *ngIf="roleOptions$ | async as roleOptions">
          <mat-option *ngFor="let role of roleOptions" [value]="role">{{ role.label }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="partners">
      <mat-label>Samarbetspartners</mat-label>
      <mat-select #partnerSelect formControlName="partner" [compareWith]="compareSelectedPartner" autocomplete="off">
        <ng-container>
          <mat-option *ngFor="let partner of partners" [value]="partner" >{{ partner.name }} </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="wideInput">
      <mat-label>Nytt lösenord</mat-label>
      <input matInput type="password" formControlName="newPassword" spellcheck="false" autocomplete="off">
    </mat-form-field>

    <!-- Force reset password for user -->
    <button mat-button (click)="resetPassword()">Återställ lösenord</button>
    <p class="reset-password-text">Vid "Återställ lösenord" skickas ett mejl till användaren med en länk för att återställa lösenordet.</p>

  </form>
</div>

<div mat-dialog-actions class="buttons">
  <button mat-button (click)="onCancelClick()">Avbryt</button>
  <div>
    <button mat-button color="warn" class="delete" (click)="onDeleteClick()">Ta bort</button>
    <button mat-raised-button color="primary" (click)="onSaveClick()" [disabled]="!editUserForm?.valid">Spara</button>
  </div>
</div>
