<section class="form-section">
  <h2>{{
    editBooking ? 'Redigera bokning ' + editBooking.qr || editBooking.bookingId : 'Ny bokning'
    }}</h2>
  <form *ngIf="bookingForm" [formGroup]="bookingForm" spellcheck="false" autocomplete="off">
    <!-- Time away -->
    <div class="form-fieldset">
      <!-- Departure date -->
      <mat-form-field>
        <mat-label>Utresa</mat-label>
        <input matInput name="departureDate" formControlName="departureDate" [matDatepicker]="departureDate"
          (input)="formatOnInputChange($event, 'departureDate')"
          (dateChange)="findFlights('departure'); updateOrder()" />
        <mat-hint>ÅÅÅÅ-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="departureDate"></mat-datepicker-toggle>
        <mat-datepicker #departureDate></mat-datepicker>
      </mat-form-field>

      <!-- Departure flight -->
      <mat-form-field>
        <mat-label>Flight utresa</mat-label>
        <input type="text" placeholder="Flight/flygplats" matInput name="selectedDepartureFlight"
          formControlName="selectedDepartureFlight" [matAutocomplete]="autoDeparture" />
        <mat-autocomplete appTabSelect #autoDeparture="matAutocomplete" [displayWith]="displayDepartureFn">
          <mat-option *ngFor="let option of filteredDepartureFlightOptions" [value]="option">
            {{
            option.flightNumber +
            ' ' +
            option.arrivalAirport +
            ' ' +
            formatTime(option.scheduledDepartureDate)
            }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="form-fieldset">
      <!-- Arrival date -->
      <mat-form-field>
        <mat-label>Hemresa</mat-label>
        <input matInput [matDatepicker]="arrivalDate" name="arrivalDate" formControlName="arrivalDate"
          (input)="formatOnInputChange($event, 'arrivalDate')" (dateChange)="findFlights('arrival'); updateOrder()" />
        <mat-hint>ÅÅÅÅ-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="arrivalDate"></mat-datepicker-toggle>
        <mat-datepicker #arrivalDate [startAt]="bookingForm.controls['departureDate'].value"></mat-datepicker>
      </mat-form-field>

      <!-- Arrival flight -->
      <mat-form-field>
        <mat-label>Flight hemresa</mat-label>
        <input type="text" placeholder="Flight/flygplats" matInput name="selectedArrivalFlight"
          formControlName="selectedArrivalFlight" [matAutocomplete]="autoArrival" />

        <mat-autocomplete appTabSelect #autoArrival="matAutocomplete" [displayWith]="displayArrivalFn">
          <mat-option *ngFor="let option of filteredArrivalFlightOptions" [value]="option">
            {{
            option.flightNumber +
            ' ' +
            option.departureAirport +
            ' ' +
            formatTime(option.scheduledArrivalDate)
            }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Parking -->
    <div class="form-fieldset parking">
      <mat-form-field>
        <mat-label>Parkering</mat-label>
        <mat-date-range-input [rangePicker]="parkingPicker">
          <input matStartDate placeholder="Startdatum" name="parkingStartDate" formControlName="parkingStartDate"
            (change)="checkAvailability(); updateOrder()" (dateChange)="updateOrder()" />
          <input matEndDate placeholder="Slutdatum" name="parkingEndDate" formControlName="parkingEndDate"
            (change)="checkAvailability(); updateOrder()" (dateChange)="updateOrder()" />
        </mat-date-range-input>
        <mat-hint>ÅÅÅÅ-MM-DD - ÅÅÅÅ-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="parkingPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #parkingPicker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="form-fieldset">
      <mat-form-field>
        <mat-label>Starttid parkering</mat-label>
        <input (input)="formatOnInputChange($event,'parkingStartTime')" (change)="updateOrder()" matInput
          placeholder="HH:mm" name="parkingStartTime" formControlName="parkingStartTime" />
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sluttid parkering</mat-label>
        <input (input)="formatOnInputChange($event,'parkingEndTime')" (change)="updateOrder()" matInput
          placeholder="HH:mm" name="parkingEndTime" formControlName="parkingEndTime" />
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>
    </div>

    <!-- Location -->
    <div class="form-fieldset location-field" *ngIf="parkingResources">
      <mat-radio-group name="location" formControlName="location" required [ngClass]="{
          error:
            bookingForm.get('location')?.invalid &&
            bookingForm.get('location')?.dirty
        }">
        <mat-label>
          <h3>Plats*</h3>
        </mat-label>
        <mat-radio-button *ngFor="let resource of parkingResources; let i = index" [value]="resource" [checked]="
            resource.resourceId ===
            bookingForm.controls['location'].value?.resourceId" color="primary">
          <app-location-option [resource]="resource"
            [resourceStatus]="parkingStatusResources?.[i]"></app-location-option>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Registration number and passengers -->
    <div class="form-fieldset">
      <mat-form-field class="registration-number">
        <mat-label class="label">Registreringsnummer</mat-label>
        <mat-icon matSuffix>directions_car</mat-icon>
        <input matInput name="registrationNumber" formControlName="registrationNumber" (change)="updateOrder()" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Antal resenärer</mat-label>
        <mat-icon matSuffix>people</mat-icon>
        <input type="number" max="100" min="1" matInput name="passengers" formControlName="passengers"
          (change)="updateOrder()" />
      </mat-form-field>
    </div>

    <!-- Prepaid ticket -->
    <div class="form-fieldset discount-field">
      <mat-form-field>
        <mat-label>{{
          availablePrepaidTickets.length > 0
          ? editBooking?.prepaidTicket?.name ? editBooking?.prepaidTicket?.name : 'Abonnemang'
          : 'Inga abonnemang tillgängliga'
          }}</mat-label>
        <mat-select formControlName="prepaid" name="prepaid"
          [value]="editBooking ? (editBooking.prepaidTicket === null || editBooking.prepaidTicket === undefined) ? 'none' : editBooking.prepaidTicket : availablePrepaidTickets[0]"
          (selectionChange)="updateOrder()">
          <mat-option *ngIf="availablePrepaidTickets.length > 0" [value]="'none'">Använd ej</mat-option>
          <mat-option *ngFor="let ticket of availablePrepaidTickets" [value]="ticket" [disabled]="ticketIsFull(ticket)">
            {{ ticket?.name }}
            ({{ ticket?.prepaidTicketId }})
            {{ ticketIsFull(ticket) ? 'fullbokad' : '' }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Features -->
    <div class="form-fieldset features-field">
      <mat-accordion *ngIf="availableMainFeatures?.length">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>
                Tjänster
                {{
                selectedFeatures.length > 0
                ? '| ' + selectedFeatures.length + ' valda'
                : ''
                }}
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="feature-category">
            <h4>Biltyp</h4>
            <div *ngIf="vehicleTypes" class="vehicle-info">
              <h5>Storlek:</h5>

              <mat-radio-group name="vehicleType" formControlName="vehicleType" (change)="this.updateFeatures()">
                <mat-radio-button *ngFor="let type of vehicleTypes" color="warn"
                  [checked]="checkedVehicleType?.label === type.label" [value]="type">{{type.label}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div *ngIf="engineTypes" class="vehicle-info">
              <h5>Motor:</h5>
              <mat-radio-group name="engineType" formControlName="engineType" (change)="this.updateFeatures()">
                <mat-radio-button *ngFor="let type of engineTypes" color="warn"
                  [checked]="checkedEngineType?.label === type.label" [value]="type">
                  {{type.label}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="feature-category" *ngFor="let mainFeature of availableMainFeatures">
            <h4>{{ mainFeature.name }}</h4>
            <mat-checkbox [formControlName]="feature.featureId?.toString() || null"
              *ngFor="let feature of mainFeature.features" (change)="this.updateFeatures()" color="warn">
              {{ feature.name }} {{ feature.price }} kr
            </mat-checkbox>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Total price -->
    <div class="total-price">
      <h4>Totalpris: </h4>
      <p>{{totalPrice | number: '1.0-0'}} kr</p>
    </div>

    <!-- Contact information -->
    <h3>Övriga uppgifter</h3>
    <div class="form-fieldset contact">
      <mat-form-field>
        <mat-label> För- & efternamn</mat-label>
        <mat-icon matSuffix>person</mat-icon>
        <input matInput name="name" formControlName="name" (change)="updateOrder()" />
      </mat-form-field>
    </div>

    <div class="form-fieldset">
      <mat-form-field>
        <mat-label> Telefonnummer</mat-label>
        <mat-icon matSuffix>phone</mat-icon>
        <input matInput name="phoneNumber" formControlName="phoneNumber" (change)="updateOrder()" />
      </mat-form-field>
      <mat-form-field>
        <mat-label> E-post</mat-label>
        <mat-icon matSuffix>email</mat-icon>
        <input type="email" matInput name="email" formControlName="email" (change)="updateOrder()" />
      </mat-form-field>
    </div>

    <!-- Luggage and child safety seat -->
    <div class="form-fieldset check-info">
      <mat-checkbox name="notOnlyCarryOnLuggage" formControlName="notOnlyCarryOnLuggage" color="primary"
        (change)="updateOrder()">
        Incheckat bagage
      </mat-checkbox>
      <mat-checkbox name="childSafetySeat" formControlName="childSafetySeat" color="primary" (change)="updateOrder()">
        Bilbarnstol
      </mat-checkbox>
    </div>

    <!-- Comment -->
    <div class="form-fieldset">
      <mat-form-field>
        <mat-label>Kommentar</mat-label>
        <textarea matInput name="comment" formControlName="comment" (change)="updateOrder()"></textarea>
      </mat-form-field>
    </div>

    <!-- News letter -->
    <div class="form-fieldset news-letter-field">
      <mat-checkbox name="newsLetter" formControlName="newsLetter" color="primary" (change)="updateOrder()">
        Kunden samtycker till nyhetsbrev.
      </mat-checkbox>
    </div>

    <div class="invalid-message" *ngIf="invalidFormMessage">
      <mat-icon>error</mat-icon> <span>{{ invalidFormMessage }}</span>
      <mat-icon>error</mat-icon>
    </div>
    <div class="duplicate-btn-wrapper" *ngIf="!editBooking">
      <button class="button slim margin-inline" (click)="onSubmit(true)">Lägg till och duplicera</button>
      <div class="info-icon">
        <mat-icon>help</mat-icon>
        <div class="show-on-info-icon-hover">
          <h4>Duplicera bokning</h4>
          <p>När du klickar på "Lägg till och duplicera" läggs bokningen till
            i aktuell order och en ny bokning påbörjas.</p>
          <p>Du kommer att se vilka bokningar som ligger med i aktuell
            order i orderrutan som kommer upp nere i högra hörnet
            efter att första bokningen är tillagd.</p>
          <p>När du fyllt i sista bokningen för ordern, klicka istället på
            "Nästa" så kommer du direkt till
            utcheckningen för ordern. Du kan också gå till utcheckning via
            knappen i inforutan.</p>
          <p>Följande information följer med till den nya bokningen vid
            duplicering:</p>
          <ul>
            <li>Avresedag och flyg</li>
            <li>Ankomstdag och flyg</li>
            <li>Parkeringsperiod med start- och sluttid</li>
          </ul>
        </div>
      </div>
    </div>
    <button class="button align-center {{showSpinner ? 'loading' : null}}" (click)="onSubmit(false)">
      {{ editBooking ? 'Spara' : 'Nästa' }}
      <mat-spinner *ngIf="showSpinner" diameter="30" color="accent"></mat-spinner></button>
  </form>
</section>