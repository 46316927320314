<!-- Back navigation -->
<button mat-flat-button class="home" [routerLink]="['/hem']">
  <mat-icon>arrow_back</mat-icon> Till startsidan
</button>

<h2>Hantera återköp</h2>

<p class="explanation">
  För att göra ett återköp måste du först välja en artikel; därefter kan du välja återbetalningsmetod.
</p>

<!-- Order info -->
<section class="order-summary">
  <h3>Ordersummering #{{order?.orderId}}</h3>
  <mat-radio-group [formControl]="refundItem">
    <div *ngFor="let item of order?.orderItems">
      <div class="item-info">
        <span *ngIf="item.orderItemType === 'FEATURE'" class="item-label"><b>{{item.feature?.name}}</b></span>
        <span *ngIf="item.orderItemType === 'RESOURCE'" class="item-label">
          <b>{{item.booking?.registrationNumber}}</b> (Bokning)
        </span>
        <span *ngIf="item.orderItemType === 'PREPAIDTICKET'" class="item-label">
          <b>{{item.prepaidTicket?.name}}</b> (Abonnemang)
        </span>
        <div>
          <span>{{item.amount}} kr</span>
          <mat-radio-button [value]="item" *ngIf="!isDisabled(item)"></mat-radio-button>
        </div>
      </div>
      <div class="discount" *ngIf="item.discount">
        <i>Rabatt: </i><i>- {{item.discount}} kr</i>
      </div>
    </div>
  </mat-radio-group>
  <div class="total-amount">
    <p><strong>{{(order?.amountToPay || 0) > 0 ? 'Kvar att betala': 'Kvar att återbetala'}}:</strong>
      <strong>{{order?.amountToPay}} kr</strong></p>
  </div>
</section>

  
<!-- Refund options -->
<div class="refund-options">
  <div>
    <h4>Betalningar</h4>
    <div *ngFor="let payment of order?.payments" class="payment-info">
      <span *ngIf="payment.transactionType !== 'REFUND'" class="payment-details">
        <p>{{payment.paymentMethod?.label}}</p>
        <p class="transaction-date">{{payment.transactionDate | date: 'yyyy-MM-dd'}}</p>
      </span>

      <span *ngIf="payment.transactionType !== 'REFUND'" class="amount-and-btn">
        <p class="payment-details">{{payment.amount}} kr</p>
        <button *ngIf="allowPayments" class="button slim success refund-btn" (click)="refundPayment(payment)" [title]="!refundItem.value ? 'Välj artikel för återbetalning' : 'Starta återbetalning'"
          [disabled]="!refundItem.value"><mat-icon class="replay">input</mat-icon></button>
      </span>
    </div>
  </div>

  <!-- Finished refunds -->
  <div *ngIf="refundExists">
    <h4>Återbetalningar</h4>
    <div *ngFor="let payment of order?.payments" class="payment-info finished-refunds">
      <span *ngIf="payment.transactionType === 'REFUND'">
        <p>{{payment.paymentMethod?.label}}</p>
        <p class="transaction-date">{{payment.transactionDate | date: 'yyyy-MM-dd'}}</p>
        <p class="transaction-date">{{payment.comment}}</p>
      </span>
      <span *ngIf="payment.transactionType === 'REFUND'">
        <p>-{{payment.amount}} kr</p>
      </span>
    </div>
  </div>

  <!-- Unfinished started payments -->
  <app-unfinished-payments *ngIf="startedPayments?.length" [startedPayments]="startedPayments" (continuePayment)="continuePayment($event)"></app-unfinished-payments>

  <!-- Navigation after refund -->
  <div class="nav-buttons">
    <button class="button success slim" [routerLink]="['/bokning', bookingId]" *ngIf="bookingId">Gå till
      detaljsidan</button>
    <button class="button success slim" [routerLink]="['/abonnemang']" *ngIf="prepaidExists">Gå till abonnemang</button>
  </div>
</div>

<!-- Receipt -->
<iframe #iframe id="iframe" name="iframe">
</iframe>