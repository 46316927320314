<div class="daily-payment-and-article-report-wrapper">
    <h2>Dagsavslut</h2>

    <!-- Datepicker -->
    <div class="date-and-export">
        <mat-form-field>
            <mat-label>Välj period</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Startdatum" [formControl]="from">
                <input matEndDate placeholder="Slutdatum" [formControl]="to">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button (click)="downloadReport()" class="button slim secondary"
            title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon></button>
    </div>

    <app-daily-article-report [hideInput]="true" [from]="from" [to]="to"></app-daily-article-report>
    <app-daily-payment-method-report [hideInput]="true" [from]="from" [to]="to"></app-daily-payment-method-report>
</div>