import { Component, Input } from '@angular/core';
import { BookingDTO } from 'parking-sdk';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() bookingsGroup?: any[];
  @Input() currentDirection: 'arrival' | 'departure' = 'arrival';
  searchResult?: any;
  termNotFound?: string;
  private onDestroy$ = new Subject<void>();
  searchInput = new FormControl<string | undefined>(undefined);
  showSpinner: boolean = false;

  constructor(private store: Store) {}

  ngOnChanges(): void {
    this.termNotFound = undefined;
    this.searchResult = undefined;
    this.searchInput.setValue(undefined);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  search(term: string | undefined): void {
    if (term === undefined || !term.trim().length || term === '') {
      this.termNotFound = undefined;
      this.searchResult = undefined;
      this.showSpinner = false;
    } else {
      this.showSpinner = true;
      this.filter(term);
    }
  }

  filter(term: string) {
    // Group is type FlightBookingDTO or HourBookingDTO
    this.showSpinner = false;
    this.searchResult = this.bookingsGroup?.flatMap((group) =>
      group.bookings.filter(
        (booking: BookingDTO) =>
          (booking?.registrationNumber
            ?.replaceAll(/\s/g, '')
            ?.toLowerCase()
            .includes(term.replaceAll(/\s/g, '').toLowerCase()) ||
            booking.qr?.toLowerCase().includes(term.toLowerCase())) &&
          ((this.currentDirection === 'arrival' &&
            booking.qtyPersons !== booking?.qtyPersonsPickedUp) ||
            (this.currentDirection === 'departure' &&
              booking.qtyPersons !== booking?.qtyPersonsDroppedOff))
      )
    );

    if (!this.searchResult?.length) {
      this.termNotFound = term;
      this.searchResult = undefined;
    }
  }

  getTotalPassengers(bookings: BookingDTO[]): number {
    return bookings.reduce(
      (acc: number, booking: BookingDTO) => (booking.qtyPersons || 0) + acc,
      0
    );
  }
}
