import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';
import {
  CreatePricelist,
  GetPricelists,
} from '../../core/states/pricelist/pricelist.actions';
import {
  DefaultService,
  FeaturePriceDTO,
  PriceDTO,
  PriceListDTO,
  RoleDTO,
} from 'parking-sdk';
import { Router } from '@angular/router';
import { CreatePricelistComponent } from 'src/app/components/create-pricelist/create-pricelist.component';
import { CopyPricelistModalComponent } from 'src/app/components/copy-pricelist-modal/copy-pricelist-modal.component';
import { UsersState } from 'src/app/core/states/users/users.state';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-pricelists',
  templateUrl: './pricelists.component.html',
  styleUrls: ['./pricelists.component.scss'],
})
export class PricelistsComponent implements OnInit {
  dataSource$: Observable<PriceListDTO[]>;
  displayedColumns: string[] = ['Namn', 'Typ', 'Datum'];
  isAdmin: boolean = false;
  unpricedItems = false;
  missingFeaturePrices?: string[];
  missingResourcePrices?: string[];

  private onDestroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private router: Router,
    private defaultService: DefaultService,
    private dateService: DateService
  ) {
    this.dataSource$ = this.store.select(PricelistState.currentPriceLists);
  }

  ngOnInit() {
    this.store.dispatch(new GetPricelists());
    this.store
      .selectOnce(UsersState.loggedInUser)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.isAdmin = user?.roles
          ? user.roles.some((role: RoleDTO) => role.name === 'ROLE_ADMIN')
          : false;
      });
    this.getFeaturePrices();
  }

  getFeaturePrices() {
    this.defaultService
      .getMissingPricesAdmin()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((missingPrices) => {
        this.missingFeaturePrices = [];
        this.missingResourcePrices = [];
        this.unpricedItems = false;

        if (
          (missingPrices.features && missingPrices.features.length > 0) ||
          (missingPrices.resources && missingPrices.resources.length > 0)
        ) {
          this.unpricedItems = true;
          missingPrices.features?.forEach((feature) => {
            this.missingFeaturePrices?.push(
              feature.featureName! +
                ', ' +
                'sista datum: ' +
                this.formatDate(feature.lastDate)
            );
          });

          missingPrices.resources?.forEach((resource) => {
            this.missingResourcePrices?.push(
              resource.resourceName! +
                ', ' +
                'sista datum: ' +
                this.formatDate(resource.lastDate)
            );
          });
        }
      });
  }

  navigateToPriceListById(priceListId: number): void {
    this.router.navigate(['/prislista', priceListId]);
  }

  openCopyPricelistModal() {
    const dialogRef = this.dialog.open(CopyPricelistModalComponent, {
      width: '500px',
      data: {},
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          const copiedPriceList: PriceListDTO = {
            name: result.name,
            resource: result.resource,
            validFrom: result.validFrom,
            validTo: result.validTo,
            prices: result.prices as PriceDTO[],
            featurePrices: result.featurePrices as FeaturePriceDTO[],
          };
          this.store.dispatch(new CreatePricelist(copiedPriceList));
        }
      });
  }

  openCreatePricelistModal() {
    const dialogRef = this.dialog.open(CreatePricelistComponent, {
      // width: '800px',
      data: {},
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          const newPriceList: PriceListDTO = {
            name: result.name,
            resource: result.resource,
            validFrom: result.validFrom,
            validTo: result.validTo,
            prices: result.prices as PriceDTO[],
          };
          this.store
            .dispatch(new CreatePricelist(newPriceList))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.store
                .selectOnce(PricelistState.selectedPriceLists)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((pricelist) => {
                  this.router.navigate(['prislista', pricelist?.priceListId]);
                });
            });
        }
      });
  }

  formatDate(date?: Date | string) {
    return (date && this.dateService.formatDate(date)) || '';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
