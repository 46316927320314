import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BookingDTO, OrderDTO, OrderItemDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import {
  DeleteOrder,
  DeleteOrderItem,
  ResetOrderState,
} from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent implements OnInit, OnDestroy {
  currentOrder?: OrderDTO;
  newOrder: boolean = true;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    public router: Router,
    private dialog: MatDialog
  ) {
    this.store.selectOnce(OrdersState.newOrder).pipe(takeUntil(this.onDestroy$)).subscribe((newOrder) => {
      this.newOrder = !newOrder;
    });
  }

  ngOnInit(): void {
    this.store.select(OrdersState.order).pipe(takeUntil(this.onDestroy$)).subscribe((order) => {
      this.currentOrder = order;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  removeOrderItem(item: OrderItemDTO) {
    if (this.currentOrder?.orderId)
      this.store.dispatch(
        new DeleteOrderItem(this.currentOrder.orderId, item.orderItemId!)
      );
  }

  removeOrder() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Avbryt order',
        message: ['Är du säker på att du vill avbryta pågående order?'],
        yesBtnText: 'Ja',
        noBtnText: 'Nej',
      },
    });

    confDialog.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      if (result) {
        if (!this.newOrder) {
          this.store.dispatch(new ResetOrderState());
        } else if (this.currentOrder?.orderId) {
          this.store.dispatch(new DeleteOrder(this.currentOrder.orderId));
        }
      }
    });
  }

  editBooking(booking: BookingDTO) {
    this.router.navigate(['redigera-bokning', booking.bookingId]);
  }
}
