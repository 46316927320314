<h3 *ngIf="!data.refund">Kontantbetalning</h3>
<h3 *ngIf="data.refund">Återbetalning</h3>

<div mat-dialog-content>
  <p *ngIf="!data.refund && !amountEntered">Att betala: {{data.order.amountToPay}} kr</p>
  <p *ngIf="data.refund && !amountEntered">Max summa för återbetalning: {{data.maxRefund}} kr</p>

  <form *ngIf="cashForm" [formGroup]="cashForm" spellcheck="false">

    <mat-form-field *ngIf="!amountEntered" class="input-field">
      <mat-label *ngIf="!data.refund">Mottagen summa</mat-label>
      <mat-label *ngIf="data.refund">Summa att återbetala</mat-label>
      <input matInput formControlName="receivedAmount" autocomplete="off">
      <mat-error>Belopp får inte överstiga det totala orderbeloppet</mat-error>
    </mat-form-field>

    <mat-form-field class="input-field" *ngIf="!amountEntered && data.refund">
      <mat-label>Anledning till återköp</mat-label>
      <input matInput type="text" autocomplete="off" formControlName="refundComment">
      <mat-error>Kommentar krävs</mat-error>
    </mat-form-field>

    <div *ngIf="cashForm.hasError('whitespace')">Ange giltig information</div>
  </form>

  <div *ngIf="amountEntered && !data.refund">
    <p>Betalning genomförd</p>
    <p>Att betala: {{data.order.amountToPay}} kr</p>
    <p>Mottaget belopp: {{cashForm && cashForm.controls['receivedAmount'].value || 0}} kr</p>
    <p>Växel: {{toReturn}} kr</p>
  </div>

  <div *ngIf="data.refund && amountEntered">
    <p>Återbetalning genomförd!</p>
  </div>

  <!-- Close/confirm -->
  <div mat-dialog-actions class="dialogActions">
    <button mat-button (click)="closeDialog()" *ngIf="!amountEntered">
      Avbryt
    </button>

    <button mat-button class="button" [ngClass]="{'loading': loading}" (click)="enterAmount()" *ngIf="!amountEntered"
      [disabled]="!cashForm?.valid">
      <span *ngIf="!data.refund">Beräkna växel</span>
      <span *ngIf="data.refund">Genomför återbetalning</span>
      <mat-spinner *ngIf="loading" diameter="25" color="accent"></mat-spinner>
    </button>

    <button mat-button (click)="closeDialog()" *ngIf="amountEntered">
      Stäng
    </button>

    <button mat-button class="button" *ngIf="amountEntered" (click)="closeAndPrintReceipt()">
      Skriv ut kvitto
    </button>
  </div>
</div>