<ng-container *ngIf="selectedPriceList$ | async as selectedPriceList">
  <!-- Tables -->
  <button mat-button (click)="navigateBack()"><mat-icon fontIcon="arrow_backward"></mat-icon>Tillbaka</button>
  <div *ngIf="!editMode" class="no-edit-form">
    <h2 class="pricelist-name">
      {{ selectedPriceList.name }}
    </h2>
    <p>Aktiv mellan <span class="h2Dates">
        {{ selectedPriceList.validFrom | date:'mediumDate' }} och {{ selectedPriceList.validTo | date:'mediumDate' }}
        <span *ngIf="!selectedPriceList.validTo">tillsvidare</span>
      </span>
    </p>

    <div class="buttons" *ngIf="isAdmin">
      <button class="edit" (click)="editPriceList()" [disabled]="editMode"><mat-icon
          title="redigera">edit</mat-icon></button>
      <button class="delete" (click)="deletePriceList()" [disabled]="editMode"><mat-icon
          title="radera">delete</mat-icon></button>
    </div>


    <div class="tables-container">
      <!-- Resource list -->
      <div class="table-wrapper" *ngIf="selectedPriceList.prices && selectedPriceList.prices.length > 0">
        <mat-table [dataSource]="selectedPriceList.prices!">

          <ng-container matColumnDef="days">
            <mat-header-cell *matHeaderCellDef>Dagar</mat-header-cell>
            <mat-cell *matCellDef="let price">{{ price.days }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="daysOperator">
            <mat-header-cell *matHeaderCellDef>Days Operator</mat-header-cell>
            <mat-cell *matCellDef="let price">{{ price.daysOperator }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="priceResource">
            <mat-header-cell *matHeaderCellDef>Pris</mat-header-cell>
            <mat-cell *matCellDef="let price">{{ price.price }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedResourceColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedResourceColumns;"></mat-row>
        </mat-table>
      </div>

      <!-- Feature list -->
      <div class="table-wrapper" *ngIf="selectedPriceList.prices!.length <= 0">
        <mat-table [dataSource]="selectedPriceList.featurePrices!">

          <ng-container matColumnDef="mainFeatureName">
            <mat-header-cell *matHeaderCellDef>Main Feature Name</mat-header-cell>
            <mat-cell *matCellDef="let featurePrice">{{ featurePrice.feature?.mainFeature?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="featureName">
            <mat-header-cell *matHeaderCellDef>Tjänst</mat-header-cell>
            <mat-cell *matCellDef="let featurePrice">{{ featurePrice.feature?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef>Pris standard</mat-header-cell>
            <mat-cell *matCellDef="let featurePrice">{{ featurePrice.price }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="priceSUV">
            <mat-header-cell *matHeaderCellDef>Pris special</mat-header-cell>
            <mat-cell *matCellDef="let featurePrice">{{ featurePrice.priceB }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedFeatureColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedFeatureColumns;"></mat-row>
        </mat-table>

        <!-- Information about empty table -->
        <div *ngIf="selectedPriceList.featurePrices!.length <= 0 && !selectedPriceList.resource" class="empty-info">
          <h4>Inga priser specificerade</h4>
          <p>Det finns ännu inga priser registrerade i denna prislista. Klicka på redigera för att lägga till priser.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit form default 31 days -->
  <div *ngIf="editMode" class="editform" [formGroup]="listEditForm">
    <h2>Redigera prislista</h2>
    <mat-form-field>
      <input matInput placeholder="Namn" [formControlName]="'listName'" name="name" required spellcheck="false"
        autocomplete="off">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Giltig från</mat-label>
      <input matInput [matDatepicker]="validFromPicker" placeholder="Giltig från" [formControlName]="'validFrom'"
        name="validFrom" required autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #validFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Giltig till</mat-label>
      <input matInput [matDatepicker]="validToPicker" placeholder="Tillsvidare" [formControlName]="'validTo'"
        name="validTo" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
      <mat-datepicker #validToPicker></mat-datepicker>
      <mat-hint>tomt = tillsvidare</mat-hint>
    </mat-form-field>

    <!-- Input fields for editing resource prices -->
    <div *ngIf="updatedPriceList.prices && updatedPriceList.prices!.length > 0">
      <div class="section-header">
        <h3>Redigera resurspriser</h3>
      </div>

      <mat-table [dataSource]="resourcesDatasource">
        <ng-container matColumnDef="days">
          <mat-header-cell *matHeaderCellDef>Dag</mat-header-cell>
          <mat-cell *matCellDef="let price">{{ price.days }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="priceResource">
          <mat-header-cell *matHeaderCellDef>Pris</mat-header-cell>
          <mat-cell *matCellDef="let price; let i = index">
            <mat-form-field class="editInputField">
              <input matInput name="priceResource_{{i}}" [formControlName]="'price' + price.priceId" required
                autocomplete="off" />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedEditResourceColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedEditResourceColumns;"></mat-row>
      </mat-table>
      
      <mat-error *ngIf="!listEditForm.valid" class="form-error">Alla dagar måste ha ett angivet pris</mat-error>
    </div>

    <!-- Input fields for editing feature prices -->
    <div *ngIf="selectedPriceList.prices!.length <= 0">
      <div class="section-header">
        <h3>Redigera tjänster</h3>
        <button mat-raised-button color="secondary" (click)="addMoreFeature()"><mat-icon
            class="addIcon">add</mat-icon></button>
      </div>
      <mat-table [dataSource]="featuresDatasource">

        <ng-container matColumnDef="featureName">
          <mat-header-cell *matHeaderCellDef>Tjänst</mat-header-cell>
          <mat-cell *matCellDef="let featurePrice">{{ featurePrice.feature?.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>Pris standard</mat-header-cell>
          <mat-cell *matCellDef="let featurePrice; let i = index">
            <mat-form-field class="editInputField">
              <input matInput name="price_{{i}}" [formControlName]="'featurePrice' + featurePrice.featurePriceId"
                required autocomplete="off" />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="priceSUV">
          <mat-header-cell *matHeaderCellDef>Pris special</mat-header-cell>
          <mat-cell *matCellDef="let featurePrice; let i = index">
            <mat-form-field class="editInputField">
              <input matInput name="priceB_{{i}}" [formControlName]="'featurePriceB' + featurePrice.featurePriceId"
                required autocomplete="off" />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let featurePrice; let i = index">
            <div class="buttons">
              <button class="delete"
                (click)="removeFeature(featurePrice.featureId, i)"><mat-icon>delete</mat-icon></button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedEditFeatureColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedEditFeatureColumns;"></mat-row>
      </mat-table>
      <mat-error *ngIf="!listEditForm.valid" class="form-error">Alla tjänster måste ha ett standardpris</mat-error>
    </div>

    <div class="formButtons">
      <button mat-raised-button color="secondary" id="cancelButton" (click)="cancelEdit()">Avbryt</button>
      <button mat-raised-button color="primary" (click)="saveChanges()">Spara</button>
    </div>
  </div>
</ng-container>