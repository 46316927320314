import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import dayjs from 'dayjs';
import { FindBookingDTO } from 'parking-sdk';
import { Subject, debounceTime, map, takeUntil } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import {
  BookingSearchListener,
  GetAllBookings,
  GetBooking,
} from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent {
  bookings: FindBookingDTO[] = [];
  errorMessage = false;
  totalBookings: number = 0;
  displayedColumns: string[] = [
    'bookingNumber',
    'registrationNumber',
    'location',
    'features',
    'flightNumber',
    'departureDate',
    'arrivalDate',
    'passengers',
    'comment',
  ];

  /* Paginator */
  pageIndex: number = 0;
  pageSize: number = 50;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  sortByColumn?: string;
  sortOrder?: 'asc' | 'desc' = 'desc';

  searchAndFilterBookingsForm?: FormGroup;

  showSpinner: boolean = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    actions$: Actions,
    private fb: FormBuilder,
    private dateService: DateService,
    private router: Router
  ) {
    actions$
      .pipe(
        ofActionDispatched(BookingSearchListener),
        map((action: BookingSearchListener) => action.term),
        debounceTime(300),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
  }

  ngOnInit() {
    this.store
      .select(BookingsState.allBookings)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.bookings = v?.content || [];
        this.totalBookings = v?.totalElements || 0;
      });

    const now = new Date();
    const oneYearBack = new Date(
      now.getFullYear() - 1,
      now.getMonth(),
      now.getDate()
    );

    /* Create form */
    this.searchAndFilterBookingsForm = this.fb.group({
      from: new FormControl<Date | undefined>(oneYearBack),
      to: new FormControl<Date | undefined>(now),
      location: new FormControl<string>('all'),
      feature: new FormControl<string>('all'),
      filterDateOn: new FormControl<'arrival' | 'departure'>('arrival'),
      searchInput: new FormControl<string | undefined>(undefined),
    });

    this.dispatchGetAllBookings();

    /* Listen to input changes */
    this.searchAndFilterBookingsForm
      ?.get('from')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
    this.searchAndFilterBookingsForm
      ?.get('to')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
    this.searchAndFilterBookingsForm
      ?.get('location')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
    this.searchAndFilterBookingsForm
      ?.get('feature')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
    this.searchAndFilterBookingsForm
      ?.get('filterDateOn')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dispatchGetAllBookings();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showBooking(row: any) {
    this.store
      .dispatch(new GetBooking(row.bookingId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    // open single page view
    this.router.navigate(['bokning', row.bookingId]);
  }

  paginator(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.dispatchGetAllBookings();
  }

  sortColumns(event: Sort) {
    this.sortByColumn = event.active;
    this.sortOrder = event.direction || undefined;

    this.dispatchGetAllBookings();
  }

  search() {
    if (
      this.searchAndFilterBookingsForm?.controls['searchInput'].value ==
        undefined ||
      !this.searchAndFilterBookingsForm.controls['searchInput'].value.trim()
        .length
    ) {
      this.showSpinner = false;
      this.dispatchGetAllBookings();
    } else {
      this.showSpinner = true;
      this.store.dispatch(
        new BookingSearchListener(
          this.searchAndFilterBookingsForm?.controls['searchInput'].value
        )
      );
    }
  }

  dispatchGetAllBookings() {
    this.errorMessage = false;
    let location =
      this.searchAndFilterBookingsForm?.controls['location']?.value;
    if (location === 'all') location = undefined;

    let feature = this.searchAndFilterBookingsForm?.controls['feature']?.value;
    if (feature === 'all') feature = undefined;

    let searchTerm =
      this.searchAndFilterBookingsForm?.controls['searchInput'].value;
    let from = this.searchAndFilterBookingsForm?.controls['from'].value;
    let to = this.searchAndFilterBookingsForm?.controls['to'].value;

    if (from && to) {
      this.store
        .dispatch(
          new GetAllBookings(
            this.pageIndex,
            this.pageSize,
            this.sortByColumn,
            this.sortOrder,
            searchTerm || undefined,
            from,
            to,
            this.searchAndFilterBookingsForm?.controls['filterDateOn'].value ||
              'departure',
            location || undefined,
            feature,
          )
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (res) => {
            this.showSpinner = false;
          },
          error: (error: any) => {
            console.error('Error fetching bookings: ', error);
            this.showSpinner = false;
            this.errorMessage = true;
          },
        });
    }
  }

  formatDate(date: string | Date | undefined): string {
    return date ? this.dateService.formatDate(date) : 'okänt';
  }

  getFlightNumber(booking: FindBookingDTO): string {
    const filterDateOn =
      this.searchAndFilterBookingsForm?.get('filterDateOn')?.value;

    switch (filterDateOn) {
      case 'arrival':
        return booking.arrivalFlightNumber || '-';
      case 'departure':
        return booking.departureFlightNumber || '-';
      default:
        return '-';
    }
  }
}
