import { NgModule } from '@angular/core';
import { AnonymizeComponent } from './anonymize.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    AnonymizeComponent,
  ],
  imports: [SharedModule],
  exports: [AnonymizeComponent],
})
export class AnonymizeModule {}
