import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import localeSv from '@angular/common/locales/sv';

import { TabSelectDirective } from './directives/tab-select.directive';
import { CustomDateAdapter } from './adapters/custom-date-adapter';

/* Rely Statistics */
import { RelyStatisticsModule } from 'rely-statistics-sdk';

/* Angular Material */
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  DateAdapter,
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';

/* Components */
import { LoginComponent } from '../components/login/login.component';
import { BookingCreateComponent } from '../pages/booking-create/booking-create.component';
import { HomeComponent } from '../pages/home/home.component';
import { SearchComponent } from '../components/search/search.component';
import { UserAdministrationComponent } from '../pages/user-administration/user-administration.component';
import { EditUserComponent } from '../components/edit-user/edit-user.component';
import { PrepaidComponent } from '../pages/prepaid/prepaid.component';
import { PrepaidCreateComponent } from '../components/prepaid-create/prepaid-create.component';
import { ParkingLotAdministrationComponent } from '../pages/parking-lot-administration/parking-lot-administration.component';
import { ParkingLotEditComponent } from '../components/parking-lot-edit/parking-lot-edit.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { LocationOptionComponent } from '../pages/booking-create/location-option/location-option.component';
import { CopyPricelistModalComponent } from '../components/copy-pricelist-modal/copy-pricelist-modal.component';
import { CreatePricelistFeatureComponent } from '../components/create-pricelist-feature/create-pricelist-feature.component';
import { CreatePricelistComponent } from '../components/create-pricelist/create-pricelist.component';
import { OrderInfoComponent } from '../components/order-info/order-info.component';
import { CheckoutComponent } from '../pages/checkout/checkout.component';
import { SideMenuComponent } from '../components/side-menu/side-menu.component';
import { SideMenuSearchComponent } from '../components/side-menu-search/side-menu-search.component';
import { CurrentBookingsComponent } from '../components/current-bookings/current-bookings.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { NavbarAdminComponent } from '../components/navbar-admin/navbar-admin.component';
import { PaxPaymentComponent } from '../components/payment/pax-payment/pax-payment.component';
import { CashPaymentComponent } from '../components/payment/cash-payment/cash-payment.component';
import { RequestPasswordResetComponent } from '../pages/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from '../pages/reset-password/reset-password.component';
import { GarageComponent } from '../pages/garage/garage.component';
import { BookingsComponent } from '../pages/bookings/bookings.component';
import { ReceiptComponent } from '../components/receipt/receipt.component';
import { PrintPickupListComponent } from '../components/print-pickup-list/print-pickup-list.component';
import { ManualRefundComponent } from '../components/payment/manual-refund/manual-refund.component';
import { RefundComponent } from '../pages/refund/refund.component';
import { KlarnaRefundComponent } from '../components/payment/klarna-refund/klarna-refund.component';
import { PaymentReportComponent } from '../pages/reports/payment-report/payment-report.component';
import { ZeroPaymentComponent } from '../components/payment/zero-payment/zero-payment.component';
import { PaxPaymentStatusComponent } from '../components/payment/pax-payment-status/pax-payment-status.component';
import { UnfinishedPaymentsComponent } from '../components/payment/unfinished-payments/unfinished-payments.component';
import { ContinuePaxPaymentComponent } from '../components/payment/continue-pax-payment/continue-pax-payment.component';
import { OrderItemsComponent } from '../components/order-items/order-items.component';
import { AdvancePaymentsReportComponent } from '../pages/reports/advance-payments-report/advance-payments-report.component';
import { DailyArticleReportComponent } from '../pages/reports/daily-article-report/daily-article-report.component';
import { DailyPaymentMethodReportComponent } from '../pages/reports/daily-payment-method-report/daily-payment-method-report.component';
import { BookingsTableComponent } from '../components/bookings-table/bookings-table.component';
import { CheckOffPassengersComponent } from "../components/bookings-table/check-off-passengers/check-off-passengers.component";
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { DailyPaymentAndArticleComponent } from '../pages/reports/daily-payment-and-article/daily-payment-and-article.component';


import { KeepHtmlPipe } from './pipes/keep-html';
import { RunScriptsDirective } from './directives/run-scripts.directive';
import { getSwePaginatorIntl } from './adapters/custom-paginator-intl';

registerLocaleData(localeSv);

@NgModule({
  declarations: [
    LoginComponent,
    BookingCreateComponent,
    HomeComponent,
    SearchComponent,
    KeepHtmlPipe,
    RunScriptsDirective,
    UserAdministrationComponent,
    EditUserComponent,
    ParkingLotAdministrationComponent,
    ParkingLotEditComponent,
    ConfirmDialogComponent,
    PrepaidComponent,
    PrepaidCreateComponent,
    LocationOptionComponent,
    CreatePricelistComponent,
    CopyPricelistModalComponent,
    CreatePricelistFeatureComponent,
    OrderInfoComponent,
    CheckoutComponent,
    SideMenuComponent,
    SideMenuSearchComponent,
    CurrentBookingsComponent,
    PaxPaymentComponent,
    CashPaymentComponent,
    ZeroPaymentComponent,
    NavbarComponent,
    NavbarAdminComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    TabSelectDirective,
    GarageComponent,
    BookingsComponent,
    ReceiptComponent,
    PrintPickupListComponent,
    RefundComponent,
    KlarnaRefundComponent,
    PaymentReportComponent,
    ManualRefundComponent,
    PaxPaymentStatusComponent,
    UnfinishedPaymentsComponent,
    ContinuePaxPaymentComponent,
    OrderItemsComponent,
    AdvancePaymentsReportComponent,
    DailyArticleReportComponent,
    DailyPaymentMethodReportComponent,
    BookingsTableComponent,
    CheckOffPassengersComponent,
    ErrorDialogComponent,
    DailyPaymentAndArticleComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    AppRoutingModule,
    MatToolbarModule,
    MatListModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSortModule,
    MatChipsModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RelyStatisticsModule,
    MatSidenavModule,
    MatCardModule,
    MatSnackBarModule,
    MatMenuModule,
    MatPaginatorModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
    { provide: LOCALE_ID, useValue: 'sv-SE' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useValue: getSwePaginatorIntl() },
  ],
  exports: [
    MatIconModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LoginComponent,
    BookingCreateComponent,
    HomeComponent,
    KeepHtmlPipe,
    RunScriptsDirective,
    UserAdministrationComponent,
    PrepaidComponent,
    PrepaidCreateComponent,
    CreatePricelistFeatureComponent,
    SideMenuComponent,
    MatSidenavModule,
    SideMenuSearchComponent,
    MatCardModule,
    OrderInfoComponent,
    PaxPaymentComponent,
    CashPaymentComponent,
    ZeroPaymentComponent,
    NavbarComponent,
    NavbarAdminComponent,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatToolbarModule,
    MatListModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSortModule,
    MatChipsModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatMenuModule,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    ReceiptComponent,
    RefundComponent,
    KlarnaRefundComponent,
    PaymentReportComponent,
    ManualRefundComponent,
    PaxPaymentStatusComponent,
    UnfinishedPaymentsComponent,
    ContinuePaxPaymentComponent,
    OrderItemsComponent,
    AdvancePaymentsReportComponent,
    DailyArticleReportComponent,
    DailyPaymentMethodReportComponent,
    BookingsTableComponent,
    CheckOffPassengersComponent,
    RelyStatisticsModule
  ],
})
export class SharedModule {}
