<div class="container" mat-dialog-content>
  <h3>Lägg till tjänst</h3>
  <mat-form-field class="wideInput">
    <mat-label>Tjänst</mat-label>
    <mat-select [formControl]="feature">
      <ng-container *ngIf="features$ | async as features">
        <mat-option *ngFor="let feature of features" [value]="feature">{{ feature.name }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="buttons">
    <button mat-raised-button (click)="cancel()">Avbryt</button>
    <button mat-raised-button color="primary" (click)="onSave()">Spara</button>
  </div>
</div>