import { Component, Input } from '@angular/core';
import { ResourceDTO, ResourceStatusDTO } from 'parking-sdk';

@Component({
  selector: 'app-location-option',
  templateUrl: './location-option.component.html',
  styleUrls: ['./location-option.component.scss'],
})
export class LocationOptionComponent {
  @Input() resource?: ResourceDTO;
  @Input() resourceStatus?: ResourceStatusDTO;
  bookedResources?: number;

  ngOnInit() {
    this.countBookedResources();
  }

  ngOnChanges() {
    this.countBookedResources();
  }

  countBookedResources(): void {
    this.bookedResources =
      (this.resourceStatus?.qty || 0) -
      (this.resourceStatus?.qtyAvailable || 0);
  }
}
