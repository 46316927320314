import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DefaultService,
  OrderDTO,
  TerminalDTO,
  TransactionStatusResponseDTO,
} from 'parking-sdk';
import * as Actions from './orders.action';
import {
  Subject,
  first,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
  Observable,
  exhaustMap,
  take,
} from 'rxjs';

export interface OrderStateModel {
  order: OrderDTO;
  newOrder: boolean;
  status: TransactionStatusResponseDTO;
  terminal: TerminalDTO;
}

@State<OrderStateModel>({
  name: 'order',
  defaults: {
    order: {},
    newOrder: true,
    status: {},
    terminal: {},
  },
})
@Injectable()
export class OrdersState {
  constructor(private defaultService: DefaultService) {}

  @Selector()
  static order(state: OrderStateModel) {
    return state.order;
  }

  @Selector()
  static newOrder(state: OrderStateModel) {
    return state.newOrder;
  }

  @Selector()
  static status(state: OrderStateModel) {
    return state.status;
  }

  @Selector()
  static terminal(state: OrderStateModel) {
    return state.terminal;
  }

  @Action(Actions.GetOrder)
  getOrder(context: StateContext<OrderStateModel>, action: Actions.GetOrder) {
    const { orderId } = action;
    return this.defaultService
      .getOrdersAdmin(undefined, 1, undefined, orderId)
      .pipe(
        tap((orders) => {
          if (orders?.content?.[0])
            context.patchState({ order: orders.content[0], newOrder: false });
        })
      );
  }

  @Action(Actions.CreateOrder)
  createOrder(context: StateContext<OrderStateModel>) {
    return this.defaultService.createOrderAdmin().pipe(
      tap((newOrder) => {
        context.patchState({ order: newOrder, newOrder: true });
      })
    );
  }

  @Action(Actions.AddOrderItem)
  addOrderItem(
    context: StateContext<OrderStateModel>,
    action: Actions.AddOrderItem
  ) {
    const { orderItem } = action;

    if (context.getState().order.orderId) {
      return this.defaultService
        .addOrderItemAdmin(context.getState().order.orderId!, orderItem)
        .pipe(
          tap((order) => {
            context.patchState({ order });
          })
        );
    }

    return this.createOrder(context).pipe(
      switchMap((newOrder) => {
        return this.defaultService
          .addOrderItemAdmin(newOrder.orderId!, orderItem)
          .pipe(
            tap((order) => {
              context.patchState({ order });
              return order;
            })
          );
      })
    );
  }

  @Action(Actions.UpdateOrderItem)
  updateOrderItem(
    context: StateContext<OrderStateModel>,
    action: Actions.UpdateOrderItem
  ): Observable<OrderDTO> {
    const { orderId, orderItemId, orderItem } = action;

    return this.defaultService
      .updateOrderItemAdmin(orderId, orderItemId, orderItem)
      .pipe(
        tap((order) => {
          context.patchState({ order: order });
        })
      );
  }

  @Action(Actions.UpdateOrder)
  updateOrder(
    context: StateContext<OrderStateModel>,
    action: Actions.UpdateOrder
  ) {
    const { orderId, order } = action;

    return this.defaultService.updateOrderAdmin(orderId, order).pipe(
      tap((updatedOrder) => {
        context.patchState({ order: updatedOrder });
      })
    );
  }

  @Action(Actions.SaveTerminal)
  saveTerminal(
    context: StateContext<OrderStateModel>,
    action: Actions.SaveTerminal
  ) {
    const { terminal } = action;

    return context.patchState({ terminal: terminal });
  }

  @Action(Actions.ContinuePaymentPax)
  ContinuePaymentPax(
    context: StateContext<OrderStateModel>,
    action: Actions.ContinuePaymentPax
  ) {
    const { orderId, uti } = action;

    return this.defaultService
      .getForcedCheckoutStatusAdmin(orderId, uti)
      .pipe(take(1))
      .subscribe({
        next: (status) => {
          context.patchState({ status: status });
          if (status.transApproved) {
            context.patchState({ status: {} });
          }
          if (status.transCancelled) {
            context.patchState({ status: {} });
          }
        },
        error: (error) => {
          console.error('Error continue card payment: ', error);
          context.patchState({ status: { errorText: 'no connection' } })
        },
      });
  }

  @Action(Actions.CheckoutOrderPax)
  checkoutOrder(
    context: StateContext<OrderStateModel>,
    action: Actions.CheckoutOrderPax
  ) {
    const { orderId, payment } = action;
    let abortTimer: Subject<void> = new Subject();

    return this.defaultService
      .checkoutOrderAdmin(orderId, payment)
      .pipe(
        first(),
        switchMap((response) => {
          if (response.uti) {
            let uti: string = response.uti;
            return timer(0, 1000).pipe(
              takeUntil(abortTimer),
              exhaustMap(() =>
                this.defaultService.getCheckoutStatusAdmin(orderId, uti).pipe(
                  takeUntil(abortTimer),
                  map((status) => {
                    context.patchState({ status: status });
                    if (status.transApproved) {
                      abortTimer.next();
                      abortTimer.complete();
                      context.patchState({ status: {} });
                    }
                    if (status.transCancelled) {
                      abortTimer.next();
                      abortTimer.complete();
                      context.patchState({ status: {} });
                    }
                    return status;
                  })
                )
              )
            );
          } else {
            return of();
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (error) => {
          context.patchState({ status: { errorText: 'no connection' } });
          console.error('Error card payment: ', error);
        },
      });
  }

  @Action(Actions.CheckoutOrderCash)
  checkoutOrderCash(
    context: StateContext<OrderStateModel>,
    action: Actions.CheckoutOrderCash
  ) {
    const { orderId, payment } = action;

    return this.defaultService.checkoutOrderAdmin(orderId, payment);
  }

  @Action(Actions.CheckoutOrderPayLater)
  checkoutOrderPayLater(
    context: StateContext<OrderStateModel>,
    action: Actions.CheckoutOrderPayLater
  ) {
    const { orderId } = action;

    return this.defaultService.checkoutOrderNoPayAdmin(orderId);
  }

  @Action(Actions.Refund)
  handleRefund(context: StateContext<OrderStateModel>, action: Actions.Refund) {
    const { orderId, orderItemId, paymentId, payment } = action;

    if (payment.paymentMethod?.paymentMethodId !== 'PAX') {
      return this.defaultService
        .refundOrderAdmin(orderId, orderItemId, paymentId, payment)
        .pipe(
          tap({
            next: () => {},
            error: (error) => {
              console.error(error);
            },
          })
        );
    } else {
      let abortTimer: Subject<void> = new Subject();

      return this.defaultService
        .refundOrderAdmin(orderId, orderItemId, paymentId, payment)
        .pipe(
          first(),
          switchMap((response) => {
            if (response.uti) {
              let uti: string = response.uti;
              return timer(0, 1000).pipe(
                takeUntil(abortTimer),
                exhaustMap(() =>
                  this.defaultService.getCheckoutStatusAdmin(orderId, uti).pipe(
                    map((status) => {
                      context.patchState({ status: status });

                      if (status.transApproved) {
                        abortTimer.next();
                        abortTimer.complete();
                        context.patchState({ status: {} });
                      }
                      if (status.transCancelled) {
                        abortTimer.next();
                        abortTimer.complete();
                        context.patchState({ status: {} });
                      }
                      return status;
                    })
                  )
                )
              );
            } else {
              return of();
            }
          })
        )
        .subscribe({
          next: () => {},
          error: (error) => {
            context.patchState({ status: { errorText: 'no connection' } });
            console.error('Error card payment: ', error);
          },
        });
    }
  }

  @Action(Actions.CancelCheckout)
  cancelCheckout(
    context: StateContext<OrderStateModel>,
    action: Actions.CancelCheckout
  ) {
    const { terminalId } = action;
    return this.defaultService.abortPaxTransactionAdmin(terminalId).pipe(
      tap((res) => {
        if (res.transCancelled) {
          // context.patchState({ order: {} });
        } else {
          console.error('Transaction cancellation failed.');
        }
      })
    );
  }

  @Action(Actions.DeleteOrderItem)
  deleteOrderItem(
    context: StateContext<OrderStateModel>,
    action: Actions.DeleteOrderItem
  ) {
    const { orderId, orderItemId } = action;

    return this.defaultService.deleteOrderItemAdmin(orderId, orderItemId).pipe(
      switchMap(() => {
        const currentState = context.getState();

        const updatedOrderItems = currentState.order.orderItems!.filter(
          (item) => item.orderItemId !== orderItemId
        );

        const updatedOrder = {
          ...currentState.order,
          orderItems: [...updatedOrderItems],
        };

        return context.dispatch(new Actions.UpdateOrder(orderId, updatedOrder));
      })
    );
  }

  @Action(Actions.DeleteOrder)
  deleteOrder(
    context: StateContext<OrderStateModel>,
    action: Actions.DeleteOrder
  ) {
    const { orderId } = action;

    return this.defaultService.deleteOrderAdmin(orderId).pipe(
      tap(() => {
        this.resetState(context);
      })
    );
  }

  @Action(Actions.ResetOrderTransState)
  resetOrderTransState(context: StateContext<OrderStateModel>) {
    context.patchState({
      status: {},
    });
  }

  //TODO: call ResetOrderState after checkout etc...
  @Action(Actions.ResetOrderState)
  resetState(context: StateContext<OrderStateModel>) {
    context.setState({
      order: {},
      newOrder: context.getState().newOrder,
      status: {},
      terminal: context.getState().terminal,
    });
  }
}
