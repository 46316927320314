import { AvailabilityDTO } from "parking-sdk";

export class GetAvailabilities {
    static readonly type = '[Availabilities] Get availabilities'
    constructor() { }
}

export class UpdateAvailability {
    static readonly type = "[Availabilities] Update existing availability";
    constructor(public availabilitytId: number, public availability: AvailabilityDTO) { };
}

export class AddAvailability {
    static readonly type = "[Availabilities] Add new availability";
    constructor(public availability: AvailabilityDTO) { };
}

export class DeleteAvailability{
    static readonly type = "[Availabilities] Delete availability";
    constructor(public availabilitytId: number) { };
}
