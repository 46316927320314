<h2>Order #{{this.order?.orderId}}</h2>
<div class="order-wrapper" *ngIf="order">
  <section class="summary-and-payments">

    <div class="summary-wrapper">
      <h3 class="summary-title">Summering</h3>
      <app-order-items [order]="order" [renderType]="'order'"></app-order-items>

      <section class="order-summary">
        <ul>
          <li class="total"><span>Total: </span><span>{{order.totalAmount}} kr</span></li>
          <li *ngIf="order.discount">
            <span>Total utan rabatt: </span>
            <span>{{order.totalAmountWithoutDiscount}} kr</span>
          </li>
          <li><span>Betalat belopp: </span><span>{{order.paidAmount}} kr</span></li>
          <li *ngIf="order.refundedAmount">
            <span>Återbetalat belopp: </span>
            <span>- {{order.refundedAmount}} kr</span>
          </li>
          <li>
            <span>{{order.amountToPay !== undefined &&
              order.amountToPay < 0 ? 'Kvar att återbetala:' : 'Kvar att betala:' }} </span>
                <span>{{order.amountToPay}} kr</span>
          </li>
        </ul>
      </section>
    </div>

    <!-- Payments -->
    <section class="payments">
      <h3>Betalningar</h3>
      <ul>
        <ng-container *ngFor="let payment of order.payments">
          <li *ngIf="payment.transactionType !== 'REFUND'">
            <div class="flex-container">
              <div class="date-and-type">
                <span>
                  {{formatDate(payment.transactionDate) || 'Okänt datum'}}
                  {{formatTime(payment.transactionDate) || 'okänd tid'}}
                </span>
                <span>{{payment.paymentMethod?.label || 'okänd betalningsmetod'}}</span>
              </div>

              <span [ngClass]="{'no-receipt': payment.paymentMethod?.paymentMethodId === 'INVOICE'
                && order.payments 
                && order.payments.length > 1}">
                {{payment?.amount}} kr
                <button *ngIf="payment.paymentMethod?.paymentMethodId !== 'INVOICE'" class="button slim"
                  [ngClass]="{'loading': loadingPaymentEmail}"
                  [title]="payment.paymentMethod?.paymentMethodId === 'KLARNA' ? 'Skicka Klarna-kvittokopia' : 'Skriv ut kvitto'"
                  (click)="payment.paymentMethod?.paymentMethodId === 'KLARNA' ? sendKlarnaReceipt(payment) : printReceipt(payment.paymentId)">
                  <mat-icon *ngIf="payment.paymentMethod?.paymentMethodId !== 'KLARNA'">receipt</mat-icon>
                  <mat-icon *ngIf="payment.paymentMethod?.paymentMethodId === 'KLARNA'">email</mat-icon>
                  <mat-spinner *ngIf="loadingPaymentEmail" diameter="25" color="accent"></mat-spinner>
                </button>
              </span>
            </div>
          </li>
        </ng-container>
      </ul>

      <!-- Invoice information -->
      <mat-accordion>
        <mat-expansion-panel *ngIf="invoice">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Fakturauppgifter</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ul class="invoice-information">
            <li><span>Organisations-/personnummer: </span><span class="value-to-end">{{
                invoice.organizationNumber
                }}</span></li>
            <li><span>Referens: </span><span class="value-to-end">{{ invoice.reference || '-' }}</span></li>
            <li><span>Namn: </span><span class="value-to-end">{{ invoice.name }}</span></li>
            <li><span>E-post kontakt: </span><span class="value-to-end">{{ invoice.email }}</span></li>
            <li><span>Telefonnummer: </span><span class="value-to-end">{{ invoice.phone }}</span></li>
            <li>
              <span>E-faktura: </span>
              <span class="value-to-end">{{ invoice.invoiceEmail || '-' }}</span>
            </li>
            <li><span>Adress: </span><span class="value-to-end">{{ invoice.address }}</span></li>
            <li><span>Postnummer: </span><span class="value-to-end">{{ invoice.zip }}</span></li>
            <li><span>Stad: </span><span class="value-to-end">{{ invoice.city }}</span></li>
            <li><span>Land: </span><span class="value-to-end">{{ invoice.country }}</span></li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="empty-content" *ngIf="!order.payments || order.payments.length <= 0">
        <p>Inga betalningar</p>
      </div>
      <p [ngClass]="{'email-msg': true, 'success': emailSent, 'failed': !emailSent}" *ngIf="emailPaymentMsg">
        {{emailPaymentMsg}}
      </p>
    </section>

    <!-- Refunds -->
    <section class="refunds" *ngIf="order.refundedAmount">
      <h3>Återbetalningar</h3>
      <ul>
        <ng-container *ngFor="let payment of order.payments">
          <li *ngIf="payment.transactionType === 'REFUND'">
            <div class="flex-container">
              <div class="date-and-type">
                <span>
                  {{formatDate(payment.transactionDate) || 'Okänt datum'}}
                  {{formatTime(payment.transactionDate) || 'okänd tid'}}
                </span>
                <span>
                  {{payment.paymentMethod?.label || 'okänd betalningsmetod'}}
                </span>
              </div>

              <span>
                -{{payment?.amount}} kr
                <button class="button slim" [ngClass]="{'loading': loadingRefundEmail}"
                  [title]="payment.paymentMethod?.paymentMethodId === 'KLARNA' ? 'Skicka Klarna-kvittokopia' : 'Skriv ut kvitto'"
                  *ngIf="payment.paymentMethod?.paymentMethodId !== 'INVOICE'"
                  (click)="payment.paymentMethod?.paymentMethodId === 'KLARNA' ? sendKlarnaReceipt(payment) : printReceipt(payment.paymentId)">
                  <mat-icon *ngIf="payment.paymentMethod?.paymentMethodId !== 'KLARNA'">receipt</mat-icon>
                  <mat-icon *ngIf="payment.paymentMethod?.paymentMethodId === 'KLARNA'">email</mat-icon>
                  <mat-spinner *ngIf="loadingRefundEmail" diameter="25" color="accent"></mat-spinner>
                </button>
              </span>
            </div>

            <p class="refund-comment">Kommentar: {{payment.comment || 'Ingen kommentar angiven'}}</p>
          </li>
        </ng-container>
      </ul>

      <p [ngClass]="{'email-msg': true, 'success': emailSent, 'failed': !emailSent}" *ngIf="emailRefundMsg">
        {{emailRefundMsg}}
      </p>
    </section>

  </section>

  <!-- Order info section -->
  <section class="info-section">
    <h3>Orderinformation</h3>
    <ul>
      <li>
        <span>Ordernummer: </span>
        <span class="value-to-end">{{ order.orderId || '-' }}</span>
      </li>
      <li>
        <span>Skapad: </span>
        <span class="value-to-end">{{ formatDate(order.createdDate) || '-' }}
          {{formatTime(order.createdDate) || '--:--'}}
        </span>
      </li>
      <li>
        <span>Skapad av: </span>
        <span class="value-to-end">{{order.createdBy?.name || order.createdBy?.username || 'okänt'}}</span>
      </li>
      <li>
        <span>Uppdaterad: </span>
        <span class="value-to-end">{{ formatDate(order.updatedDate) || '-' }}
          {{formatTime(order.updatedDate) || '--:--'}}
        </span>
      </li>
      <li>
        <span>Uppdaterad av: </span>
        <span class="value-to-end">{{ order.updatedBy?.name || order.updatedBy?.username || 'okänt' }}</span>
      </li>
      <li>
        <span>Status: </span>
        <span class="value-to-end">
          {{!order.payments?.length && order.orderStatus && order.orderStatus !== 'COMPLETED' ?
          'ORDER EJ BEHANDLAD. VÄLJ BETALNINGSMETOD.': 'Skapad'}}
        </span>
      </li>
      <li *ngIf="!invoice">
        <span> Betalningsstatus: </span>
        <span class="value-to-end">{{ order.paymentStatus?.label || 'okänt' }}</span>
      </li>
    </ul>

    <section class="action-buttons">
      <!-- Handle payment -->
      <button class="button"
        [ngClass]="{'unfinished-payments': hasUnfinishedPayments, 'warning': hasUnfinishedPayments}"
        (click)="goToCheckout()" [disabled]="cancelledBookings">
        {{hasUnfinishedPayments ? 'Hantera påbörjade betalningar ' : 'Hantera betalning'}}
        <mat-icon *ngIf="hasUnfinishedPayments">warning</mat-icon>
      </button>

      <!-- Order confirmation -->
      <button class="button" [ngClass]="{'loading': loadingConfirmationEmail}" [disabled]="cancelledBookings" (click)="sendConfirmationEmail()">Skicka orderbekräftelse
        <mat-spinner *ngIf="loadingConfirmationEmail" diameter="25" color="accent"></mat-spinner>
      </button>
      <p [ngClass]="{'email-msg': true, 'success': emailConfirmationSent, 'failed': !emailConfirmationSent}" *ngIf="emailConfirmationMsg">
        {{emailConfirmationMsg}}
      </p>
    </section>
  </section>
</div>

<!-- Receipt -->
<iframe #iframe id="iframe" name="iframe">
</iframe>