import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderDTO, PaymentDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { CheckoutOrderCash } from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-zero-payment',
  templateUrl: './zero-payment.component.html',
  styleUrls: ['./zero-payment.component.scss'],
})
export class ZeroPaymentComponent implements OnInit {
  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialogRef<ZeroPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderDTO, refund: PaymentDTO, maxRefund: number, orderItemId: number },
    private store: Store
  ) {
    dialog.disableClose = true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  closeDialog(success: boolean) {
    this.checkout(false);
  }

  closeAndPrintReceipt() {
    this.checkout(true);
  }

  checkout(printReceipt: boolean) {
    let payment: PaymentDTO = {
      paymentMethod: { paymentMethodId: 'CASH' },
      transactionType: 'PAYMENT',
      amount: 0,
    };

    this.store
    .dispatch(new CheckoutOrderCash(this.data.order.orderId!, payment)).pipe(takeUntil(this.onDestroy$))
    .subscribe(() => {
      if (printReceipt) {
        this.dialog.close({ success: true, receipt: true });
      } else {
        this.dialog.close({ success: true });
      }
    });
  }
}
