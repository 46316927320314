import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { BookingDTO } from 'parking-sdk';
import { Subject, debounceTime, map, takeUntil } from 'rxjs';
import { BookingSearchListener, GetGarageBookings } from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';

@Component({
  selector: 'app-garage',
  templateUrl: './garage.component.html',
  styleUrls: ['./garage.component.scss']
})
export class GarageComponent {
  bookings: BookingDTO[] = [];
  dispatchBookingsErrorCode?: any;
  totalBookings: number = 0;
  currentlyInGarage: number = 0;
  pageIndex: number = 0;
  pageSize: number = 50;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  sortByColumn?: string;
  sortOrder?: 'asc' | 'desc';
  searchInput = new FormControl<string | undefined>(undefined);
  private onDestroy$ = new Subject<void>();
  showSpinner: boolean = false;

  constructor(private store: Store, actions$: Actions){
    actions$
    .pipe(
      ofActionDispatched(BookingSearchListener),
      map((action: BookingSearchListener) => action.term),
      debounceTime(300),
      takeUntil(this.onDestroy$)
    )
    .subscribe((term) => {
      this.store.dispatch(new GetGarageBookings(0, this.pageSize, this.sortByColumn, this.sortOrder, term))
      .subscribe(() => this.showSpinner = false)
    });
  }

  ngOnInit(){
    this.store.dispatch(new GetGarageBookings(this.pageIndex, this.pageSize));
    this.store.select(BookingsState.currentGarageBookings).pipe(takeUntil(this.onDestroy$)).subscribe(v => {
      this.bookings = v?.content ? v.content : [];
      this.totalBookings = v?.totalElements ? v.totalElements : 0; 
      if(!this.searchInput.value) this.currentlyInGarage = v?.totalElements ? v.totalElements : 0; 
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  paginator(event: PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.store.dispatch(new GetGarageBookings(this.pageIndex, this.pageSize, this.sortByColumn, this.sortOrder))
  }

  sortColumns(event: {column?: string, sort?:'asc' | 'desc'}){
    const { column, sort }= event;
    this.sortByColumn = column;
    this.sortOrder = sort;
    this.store.dispatch(new GetGarageBookings(this.pageIndex, this.pageSize, this.sortByColumn, this.sortOrder));
  }

  search(){
    if (this.searchInput.value == undefined || !this.searchInput.value.trim().length) {
      this.showSpinner = false;
      this.store.dispatch(new GetGarageBookings(this.pageIndex, this.pageSize, this.sortByColumn, this.sortOrder));
    } else {
      this.showSpinner = true;
      this.bookings = []
      this.store.dispatch(new BookingSearchListener( this.searchInput.value ));
    }
  }
}
