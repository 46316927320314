import { Component, Input } from '@angular/core';
import { LogOrderDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
  @Input() historyLog?: LogOrderDTO[];

  constructor(private dateService: DateService) {}

  formatDate(date: string | Date | undefined): string {
    return date ? this.dateService.formatDate(date) : '';
  }

  formatTime(date: string | Date | undefined): string {
    return date ? this.dateService.formatTime(date) : '';
  }
}
