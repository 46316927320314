<div>
  <div class="logo-title-wrapper">
    <img
      src="../assets/images/lindskrog_logo.png"
      alt="Lindskrog logotyp"
    />
  </div>
  <p>
    Din QR-kod till parkeringen.
  </p>
  <img [src]="qrUrl">
</div>