import { OrderDTO, OrderItemDTO, PaymentDTO, TerminalDTO } from "parking-sdk";

export class GetOrder {
  static readonly type = "[Order] Get order";
  constructor(public orderId: number) { }
}

export class CreateOrder {
  static readonly type = "[Order] Create a new order";
  constructor() { }
}

export class UpdateOrderItem {
  static readonly type = "[Order] Update existing order item";
  constructor(public orderId: number, public orderItemId: number, public orderItem: OrderItemDTO) { }
}

export class AddOrderItem {
  static readonly type = "[Order] Add order item to existing order";
  constructor(public orderItem: OrderItemDTO) { }
}

export class UpdateOrder {
  static readonly type = "[Order] Update existing order";
  constructor(public orderId: number, public order: OrderDTO) { }
}

export class CheckoutOrderPax {
  static readonly type = "[Order] Checkout order and pay with card";
  constructor(public orderId: number, public payment: PaymentDTO) { }
}

export class ContinuePaymentPax {
  static readonly type = "[Order] Continue started pax payment";
  constructor(public orderId: number, public uti: string) { }
}

export class CheckoutOrderCash {
  static readonly type = "[Order] Checkout order and pay with cash";
  constructor(public orderId: number, public payment: PaymentDTO) {  }
}

export class CheckoutOrderPayLater {
  static readonly type = "[Order] Checkout order and pay later";
  constructor(public orderId: number) {  }
}

export class Refund {
  static readonly type = "[Order] Process order refund";
  constructor(public orderId: number, public orderItemId: number, public paymentId: number, public payment: PaymentDTO) {}
}

export class SaveTerminal {
  static readonly type = "[Order] Save last used payment terminal";
  constructor(public terminal: TerminalDTO) { }
}

export class GetPaymentStatus {
  static readonly type = "[Order] Get payment status for order";
  constructor(public orderId: number, public uti: string) { }
}

export class CancelCheckout {
  static readonly type = "[Order] Cancel active payment";
  constructor(public terminalId: number) {}
}

export class DeleteOrder {
  static readonly type = "[Order] Delete order";
  constructor(public orderId: number) { }
}

export class DeleteOrderItem {
  static readonly type = "[Order] Delete order item";
  constructor(public orderId: number, public orderItemId: number) { }
}

export class ResetOrderTransState {
  static readonly type = "[Order] Reset order transaction state";
  constructor() { }
}

export class ResetOrderState {
  static readonly type = "[Order] Reset order state";
  constructor() { }
}

export class OrderSearchListener {
  static readonly type = `[Orders] Listener for Search orders`;
  constructor(public term: string) {}
}