import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { DefaultService, FeatureDTO, FeaturePriceDTO, MainFeatureDTO, PriceDTO, PriceListDTO, Resource, ResourceDTO } from 'parking-sdk'
import * as Actions from './pricelist.actions'
import { Observable, tap } from 'rxjs'

export interface PriceListStateModel {
  pricelists: PriceListDTO[]
  selectedPriceList: PriceListDTO | null
  feature: FeatureDTO[] //Tillägstjänster
  resource?: ResourceDTO[] //Parkering utomhus, Garage med laddning etc...
}

@State<PriceListStateModel>({
  name: 'pricelist',
  defaults: {
    pricelists: [],
    selectedPriceList: null,
    feature: [],
    resource: undefined,
  },
})
@Injectable()
export class PricelistState {
  constructor(private defaultService: DefaultService) {}

  @Selector()
  static currentPriceLists(state: PriceListStateModel) {
    return state.pricelists
  }

  @Selector()
  static selectedPriceLists(state: PriceListStateModel) {
    return state.selectedPriceList
  }

  @Selector()
  static currentFeatures(state: PriceListStateModel) {
    return state.feature
  }

  @Selector()
  static currentResources(state: PriceListStateModel) {
    return state.resource
  }

  @Action(Actions.GetPricelists)
  getPriceLists(context: StateContext<PriceListStateModel>) {
    return this.defaultService.getPriceListsAdmin().pipe(
      tap((pricelists) => {
        context.patchState({ pricelists: pricelists})
      })
    )
  }

  @Action(Actions.GetPricelistById)
  getPriceList(context: StateContext<PriceListStateModel>, action: Actions.GetPricelistById) {
    return this.defaultService.getPriceListAdmin(action.priceListId).pipe(
      tap((priceList) => {
        context.patchState({ selectedPriceList: priceList })
      })
    )
  }

  @Action(Actions.CreatePricelist)
  createPricelist(context: StateContext<PriceListStateModel>, action: Actions.CreatePricelist) {
    return this.defaultService.createPriceListAdmin(action.priceList).pipe(
      tap({
        next: (res) => {
          context.dispatch(new Actions.GetPricelists());
          context.patchState({selectedPriceList: res});
        },
        error: (error) => {
          console.error('Could not create pricelist: ', error);
        }
      })
    );
  }

  @Action(Actions.DeletePricelist)
  deletePricelist(context: StateContext<PriceListStateModel>, action: Actions.DeletePricelist) {
    return this.defaultService.deletePriceListAdmin(action.priceListId).pipe(
      tap(() => {
        const pricelists = context.getState().pricelists.filter(
          (priceList) => priceList.priceListId !== action.priceListId
        );
        context.patchState({
          pricelists,
          selectedPriceList: null, // Clear selectedPriceList after deletion
        });
      })
    );
  }

  @Action(Actions.UpdatePriceList)
  updatePricelist(context: StateContext<PriceListStateModel>, action: Actions.UpdatePriceList) {
    const priceListId = action.priceList.priceListId;

    if (priceListId) {
      return this.defaultService.updatePriceListAdmin(priceListId, action.priceList).pipe(
        tap((updatedData) => {
          context.patchState({
            selectedPriceList: updatedData,
          });
          context.dispatch(new Actions.GetPricelists());
        }),
      );
    } else {
      return undefined;
    }
  }

  @Action(Actions.GetFeatures)
  getFeatures(context: StateContext<PriceListStateModel>, action: Actions.GetFeatures) {
    return this.defaultService.findFeaturesDeprecated(action.mainFeatureType).pipe(
      tap((features) => {
        context.patchState({ feature: features});
      })
    );
  }

  @Action(Actions.GetAllFeatures)
  getAllFeatures(context: StateContext<PriceListStateModel>) {
    return this.defaultService.getFeaturesAdmin().pipe(
      tap((features) => {
        context.patchState({feature: features});
      })
    );
  }

  @Action(Actions.GetResources)
  getResources(context: StateContext<PriceListStateModel>) {
    return this.defaultService.getResources().pipe(
      tap((resources) => {
        context.patchState({ resource: resources});
      })
    );
  }
}
