import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

/*  NGXS */
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

/* States */
import { UsersState } from './core/states/users/users.state';
import { BookingsState } from './core/states/bookings/bookings.state';
import { PrepaidState } from './core/states/prepaid/prepaid.state';
import { FeaturesState } from './core/states/features/feature.state';
import { AvailabilitiesState } from './core/states/availabilities/availabilities.state';
import { PricelistState } from './core/states/pricelist/pricelist.state';
import { ReportsState } from './core/states/reports/reports.state';
import { OrdersState } from './core/states/orders/orders.state';
import { PartnerState } from './core/states/partner/partner.state';

/* Page modules TODO: Create shared pages module and import that in shared. */
import { BookingModule } from './pages/booking/booking.module';
import { PricelistModule } from "./pages/pricelist/pricelist.module";
import { PricelistsModule } from "./pages/pricelists/pricelists.module";
import { SmsSettingsModule } from './pages/sms-settings/sms-settings.module';
import { OrdersModule } from "./pages/orders/orders.module";
import { AnonymizeModule } from "./pages/anonymize/anonymize.module";
import { RegisterModule } from "./components/register/register.module";
import { OccupancyReportModule } from './pages/reports/occupancy-report/occupancy-report.module';
import { OrderModule } from "./pages/order/order.module";
import { ScheduleModule } from "./pages/schedule/schedule.module";
import { PartnershipModule } from './pages/partnership/partnership.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    PricelistModule,
    PricelistsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    BookingModule,
    SmsSettingsModule,
    AnonymizeModule,
    OrdersModule,
    RegisterModule,
    OccupancyReportModule,
    OrderModule,
    ScheduleModule,
    PartnershipModule,
    NgxsModule.forRoot([
      UsersState,
      BookingsState,
      PrepaidState,
      FeaturesState,
      AvailabilitiesState,
      PricelistState,
      ReportsState,
      OrdersState,
      PartnerState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      deserialize: (state: any) => JSON.parse(atob(state)),
      serialize: (state: any) => btoa(JSON.stringify(state)),
      keys: ['order.order', 'users.loggedInUser', 'partner.currentPartner'],
      storage: StorageOption.SessionStorage
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
