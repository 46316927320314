<h2>Avidentifiering</h2>
<div class="content">

    <section>
        <h3>Syfte</h3>
        <p>Om en kund kontaktar Lindskrog och begär avidentifiering av sina personuppgifter, är det viktigt att vi
            agerar i
            enlighet med lagar och regler om EU:s dataskyddsförordning <strong>GDPR</strong>. </p>
        <p>Om sådana förfrågningar kommer in, ska vi höra av oss
            till <strong>Rely IT</strong>. De säkerställer att kundens önskemål
            om avidentifiering hanteras korrekt och i enlighet med gällande
            lagstiftning. </p>
        <p> Det är av yttersta vikt att alla riktlinjer och procedurer följs för att skydda kundens
            integritet och upprätthålla förtroendet för Lindskrog.</p>
    </section>

    <section>
        <h3>Så gör du</h3>
        <p>Skicka ett mejl till Rely IT med ämnet "Avidentifiering Lindskrog". Ange i mejlet ett ordernummer och/eller
            bokningsnummer med en kort beskrivande text, så avidentifierar Rely IT alla personuppgifter kopplade till
            denna. Rely IT återkommer med en bekräftelse per e-post när personen är avidentifierad.</p>
        <p>Statistik om ordern
            kommer att finnas kvar i systemet, men utan personuppgifter.</p>
        <p><strong>E-post: </strong> <a href="mailto:helpdesk@relyit.se">helpdesk{{'@'}}relyit.se</a></p>
        <p class="warning-text"><strong>OBS! Skicka inte några personuppgifter kopplade till kunden i mejlet! Rely IT hittar dessa genom
                boknings-/ordernumret.</strong></p>
    </section>
</div>