import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { AvailabilityDTO } from 'parking-sdk';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ParkingLotEditComponent } from 'src/app/components/parking-lot-edit/parking-lot-edit.component';
import { GetAvailabilities } from 'src/app/core/states/availabilities/availabilities.actions';
import { AvailabilitiesState } from 'src/app/core/states/availabilities/availabilities.state';


@Component({
  selector: 'app-parking-lot-administration',
  templateUrl: './parking-lot-administration.component.html',
  styleUrls: ['./parking-lot-administration.component.scss']
})
export class ParkingLotAdministrationComponent implements OnInit{

  dataSource = new MatTableDataSource<AvailabilityDTO>();
  displayedColumns = ['name', 'qty', 'validFrom', 'validTo'];
  availabilities$: Observable<AvailabilityDTO[]> = this.store.select(AvailabilitiesState.currentAvailabilities);

  private onDestroy$ = new Subject<void>();

  constructor( private dialog: MatDialog, private store: Store){
    this.store.dispatch(GetAvailabilities);
  }

  ngOnInit(): void {
    this.availabilities$.pipe(takeUntil(this.onDestroy$)).subscribe(availabilities => {
      this.dataSource.data = availabilities.filter(availability => availability.availabilityType === 'QTY_PER_DAY')
    })
  }

  createAvailability(){
    this.dialog.open(ParkingLotEditComponent, {
      width: '600px',
      data: {}
    });
  }

  editAvailability(availability: AvailabilityDTO){
    this.dialog.open(ParkingLotEditComponent, {
      width: '600px',
      data: {availability}
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
