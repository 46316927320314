import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  BookingDTO,
  FeatureWithPriceDTO,
  FlightDTO,
  MainFeatureDTO,
  OrderDTO,
  PrepaidTicketDTO,
  ResourceDTO,
  ResourceStatusDTO,
} from 'parking-sdk';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor() {}

  calculateTotalPrice(
    location?: ResourceDTO,
    parkingStatusResources?: ResourceStatusDTO[],
    selectedFeatures?: FeatureWithPriceDTO[],
    selectedPrepaidTicket?: PrepaidTicketDTO | 'none'
  ): number {
    let totalPrice;
    const parkingStatus =
      parkingStatusResources?.filter(
        (status) => status.resource?.name === location?.name
      ) || [];
    if (selectedPrepaidTicket && selectedPrepaidTicket !== 'none') {
      totalPrice = 0;
    } else {
      totalPrice = parkingStatus[0]?.price ? parkingStatus[0].price : 0;
    }
    const featureTotal = selectedFeatures?.reduce(
      (acc: number, feature: FeatureWithPriceDTO): number => {
        return acc + (feature.price ? feature.price : 0);
      },
      0
    );
    totalPrice += featureTotal || 0;

    return totalPrice;
  }

  filterSelectedFeatures(
    bookingForm: FormGroup,
    availableMainFeatures: MainFeatureDTO[]
  ) {
    return (
      availableMainFeatures.flatMap(
        (mainFeature: MainFeatureDTO) =>
          mainFeature.features?.filter(
            (feature: FeatureWithPriceDTO) =>
              feature.featureId &&
              bookingForm?.controls[feature.featureId.toString()]?.value
          ) || []
      ) || []
    );
  }

  filterFlights(
    flights: FlightDTO[],
    direction: 'departure' | 'arrival',
    term: string
  ): FlightDTO[] {
    const type =
      direction === 'departure' ? 'arrivalAirport' : 'departureAirport';

    return flights.filter(
      (option) =>
        term &&
        (option.flightNumber
          ?.toUpperCase()
          .includes(term.toUpperCase().replace(/\s/g, '')) ||
          option[type]?.toUpperCase().includes(term.toUpperCase()))
    );
  }

  hasFeature(order: OrderDTO, featureId: number, booking: BookingDTO): boolean {
    return (
      order.orderItems?.some(
        (item) =>
          item.orderItemType === 'FEATURE' &&
          item.feature?.featureId === featureId &&
          item.booking?.bookingId &&
          item.booking.bookingId === booking.bookingId
      ) || false
    );
  }

  ticketIsFull(ticket: any, currentBooking: BookingDTO): boolean {
    return ticket
      ? (ticket.bookings?.length || 0) >= ticket.maxPark &&
          !ticket.bookings?.find(
            (booking: BookingDTO) =>
              booking.registrationNumber === currentBooking.registrationNumber
          )
      : false;
  }
}
