import { UserDTO } from "parking-sdk"


export class GetUsers {
    static readonly type = '[Users] Get Users'
    constructor() { }
}

export class SetLoggedInUser {
    static readonly type = '[Users] Set logged in user'
    constructor() {}
}
export class AddUser {
    static readonly type = '[Users] Add User'
    constructor(public user: UserDTO) { }
}
export class UpdateUser {
    static readonly type = '[Users] Update User';
    constructor(public userId: number, public updatedUser: UserDTO) { }
  }
export class RemoveUser {
    static readonly type = '[Users] Remove User'
    constructor(public userId: number) { }
}

export class GetRoles {
    static readonly type = '[Users] Get Roles'
    constructor() { }
}

export class ResetUsersState {
    static readonly type = '[Users] Reset user state'
    constructor() { }
}