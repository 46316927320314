import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { PriceListDTO } from 'parking-sdk';
import { Observable } from 'rxjs';
import { GetPricelists } from 'src/app/core/states/pricelist/pricelist.actions';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';

@Component({
  selector: 'app-copy-pricelist-modal',
  templateUrl: './copy-pricelist-modal.component.html',
  styleUrls: ['./copy-pricelist-modal.component.scss'],
})
export class CopyPricelistModalComponent implements OnInit {
  currentPriceLists$: Observable<PriceListDTO[]>;
  newPriceList: PriceListDTO = {
    name: '',
    validFrom: new Date(),
    validTo: new Date(),
    prices: [],
    resource: {},
    featurePrices: [],
  };
  selectedPricelist!: PriceListDTO | null;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CopyPricelistModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentPriceLists$ = this.store.select(
      PricelistState.currentPriceLists
    );
  }

  onCopyClick() {
    if (!this.newPriceList.name) {
      this.newPriceList.name = `Kopia av ${this.selectedPricelist!.name}`;
    }

    this.dialogRef.close({
      name: this.newPriceList.name,
      validFrom: this.selectedPricelist!.validFrom,
      validTo: this.selectedPricelist!.validTo,
      prices: this.selectedPricelist!.prices,
      resource: this.selectedPricelist!.resource,
      featurePrices: this.selectedPricelist!.featurePrices,
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.store.dispatch(new GetPricelists());
  }
}
