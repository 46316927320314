import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { BookingDTO, DefaultService, SettingDTO } from 'parking-sdk';
import { Subject, forkJoin, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss'],
})
export class SmsSettingsComponent {
  postText = new FormControl<string>('', { nonNullable: true });
  preText = new FormControl<string>('', { nonNullable: true });

  currentSMSPreSetting: SettingDTO = {};

  staticMiddleText = '';
  dummyBooking: BookingDTO = {
    qtyPersons: 2, 
    registrationNumber: 'ABC123', 
    qr: '123456', 
    departureDate: new Date(), 
    arrivalDate: dayjs().add(1, 'week').toDate(),
    arrivalFlight: {flightNumber: 'SK123'},
    departureFlight: {flightNumber: 'SK456'},
    resource: {
      name: 'PARKING_OUTDOOR',
      label: 'Utomhus'
    }
  }
  currentSMSPostSetting: SettingDTO = {};

  previewData = { text: '', length: 0, numOfSMS: 0 };
  postTextChanged: boolean = false;
  preTextChanged: boolean = false;
  reminderTextChanged: boolean = false;
  private onDestroy$ = new Subject<void>();

  constructor(private defaultService: DefaultService) {
    this.defaultService.getSmsPreviewAdmin(this.dummyBooking).pipe(takeUntil(this.onDestroy$)).subscribe((text) => {
      if(text.body) {
        this.staticMiddleText = text.body;
      }
      else {
        console.error('No response from backend');
      }
      this.preview();
    })
  }

  ngOnInit() {
    this.postText.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.postTextChanged = true;
      this.preview();
    });
    this.preText.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.preTextChanged = true;
      this.preview();
    });

    forkJoin([
      this.defaultService.getSetting('SMS_PRE_MESSAGE'),
      this.defaultService.getSetting('SMS_POST_MESSAGE'),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([pre, post]) => {
        if (pre.value) {
          this.preText.setValue(pre.value, { emitEvent: false });
          this.currentSMSPreSetting = pre;
        }
        if (post.value) {
          this.postText.setValue(post.value, { emitEvent: false });
          this.currentSMSPostSetting = post;
        }
        this.preview();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  save(field: 'PRE' | 'POST') {
    switch (field) {
      case 'PRE':
        this.preTextChanged = false;
        this.defaultService
          .updateSetting('SMS_PRE_MESSAGE', {
            ...this.currentSMSPreSetting,
            value: this.preText.value,
          }).pipe(takeUntil(this.onDestroy$))
          .subscribe((pre) => (this.currentSMSPreSetting = pre));
        break;
      case 'POST':
        this.postTextChanged = false;
        this.defaultService
          .updateSetting('SMS_POST_MESSAGE', {
            ...this.currentSMSPostSetting,
            value: this.postText.value,
          }).pipe(takeUntil(this.onDestroy$))
          .subscribe((post) => (this.currentSMSPostSetting = post));
        break;
    }
    this.preview();
  }

  reset(field: 'PRE' | 'POST') {
    switch (field) {
      case 'PRE':
        this.preTextChanged = false;
        this.preText.setValue(this.currentSMSPreSetting.value || '', {
          emitEvent: false,
        });
        break;
      case 'POST':
        this.postTextChanged = false;
        this.postText.setValue(this.currentSMSPostSetting.value || '', {
          emitEvent: false,
        });
        break;
    }
    this.preview();
  }

  preview() {
    let numOfSMS = 0;
    const totalTextLength = this.staticMiddleText.length;

    numOfSMS = totalTextLength <= 160 ? 1 : Math.ceil(totalTextLength / 153);

    this.previewData = {
      text: this.staticMiddleText,
      length: totalTextLength,
      numOfSMS: numOfSMS,
    };
  }
}
