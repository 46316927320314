import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { FeatureDTO, FeaturePriceDTO } from 'parking-sdk';
import { Observable } from 'rxjs';
import { GetAllFeatures } from 'src/app/core/states/pricelist/pricelist.actions';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';

@Component({
  selector: 'app-create-pricelist-feature',
  templateUrl: './create-pricelist-feature.component.html',
  styleUrls: ['./create-pricelist-feature.component.scss']
})
export class CreatePricelistFeatureComponent implements OnInit{
  newFeature: FeaturePriceDTO = {price: undefined, feature: {}};
  features$: Observable<FeatureDTO[]> = this.store.select(PricelistState.currentFeatures);
  feature = new FormControl<FeaturePriceDTO | undefined>(undefined);

  constructor(private store: Store,
    public dialogRef: MatDialogRef<CreatePricelistFeatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
  
  ngOnInit() {
    this.store.dispatch(new GetAllFeatures());
  }
  
  cancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close({
      feature: this.feature.value!
    });
  }
}
