import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderDTO, PaymentDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { FormService } from 'src/app/core/services/form.service';
import {
  CheckoutOrderCash,
  GetOrder,
  Refund,
  ResetOrderState,
} from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss'],
})
export class CashPaymentComponent implements OnInit {
  toReturn: number = 0;
  amountEntered = false;
  transactionSuccess = false;
  loading = false;
  cashForm?: FormGroup;

  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialogRef<CashPaymentComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: OrderDTO;
      refund: PaymentDTO;
      maxRefund: number;
      orderItemId: number;
    },
    private store: Store,
    private formService: FormService
  ) {
    dialog.disableClose = true;
  }

  ngOnInit(): void {
    /* Create form */
    this.cashForm = this.formBuilder.group({
      receivedAmount: new FormControl<number | undefined>(
        this.data.order.amountToPay,
        [Validators.required, Validators.min(0)]
      ),
      refundComment: new FormControl<string | undefined>(''),
    });

    /* Adjust validators and default value */
    if (this.data.refund) {
      this.cashForm.controls['receivedAmount'].addValidators(
        Validators.max(this.data.maxRefund)
      );
      this.cashForm.controls['refundComment'].addValidators(
       [ Validators.required, this.formService.noWhitespaceValidator]
      );
      if (this.data.maxRefund) {
        this.cashForm?.controls['receivedAmount'].setValue(this.data.maxRefund);
      }
    } else {
      this.cashForm.controls['receivedAmount'].addValidators(
        Validators.max(this.data?.order?.amountToPay || 0)
      );
    }
  }

  enterAmount() {
    this.loading = true;
    // Payment
    if (!this.data.refund) {
      let recievedAmount = this.cashForm?.controls['receivedAmount'].value;

      if (recievedAmount !== undefined && recievedAmount !== null) {
        let paidAmount = recievedAmount;

        /* Set payment to max amout to pay */
        if (paidAmount > this.data.order.amountToPay!) {
          paidAmount = this.data.order.amountToPay!;
        }

        let payment: PaymentDTO = {
          paymentMethod: { paymentMethodId: 'CASH' },
          transactionType: 'PAYMENT',
          amount: paidAmount,
        };

        this.store
          .dispatch(new CheckoutOrderCash(this.data.order.orderId!, payment))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.amountEntered = true;
            this.transactionSuccess = true;
            if (recievedAmount)
              this.toReturn = recievedAmount - this.data.order.amountToPay!;
            if (this.toReturn < 0) {
              this.toReturn = 0;
            }
            this.loading = false;
          });
      }
    }
    // Refund
    else {
      if (this.cashForm?.valid) {
        const payment: PaymentDTO = {
          paymentMethod: this.data.refund.paymentMethod,
          transactionType: 'REFUND',
          amount: this.cashForm.controls['receivedAmount'].value,
          comment: this.cashForm.controls['refundComment'].value,
        };

        this.store
          .dispatch(
            new Refund(
              this.data.order.orderId!,
              this.data.orderItemId,
              this.data.refund.paymentId!,
              payment
            )
          )
          .subscribe(() => {
            this.store.dispatch(new ResetOrderState());
            this.data.order.orderId &&
              this.store
                .dispatch(new GetOrder(this.data.order.orderId))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((v) => {
                  this.amountEntered = true;
                  this.transactionSuccess = true;
                  this.loading = false;
                });
          });
      }
    }
  }

  closeDialog() {
    if (this.transactionSuccess) {
      this.dialog.close({
        success: true,
        paidAmt: this.cashForm?.controls['receivedAmount'].value!,
      });
    } else {
      this.dialog.close();
    }
  }

  closeAndPrintReceipt() {
    this.dialog.close({
      success: true,
      receipt: true,
      paidAmt: this.cashForm?.controls['receivedAmount'].value!,
    });
  }

  

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
