import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerServiceService {
  private contentSource = new BehaviorSubject<string>(''); // Default to empty
  currentContent = this.contentSource.asObservable();

  constructor() { }

  changeContent(content: string) {
    this.contentSource.next(content);
  }
}
