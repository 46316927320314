import { NgModule } from '@angular/core';
import { ScheduleComponent } from './schedule.component';
import { TravellersChartComponent } from "./travellers-chart/travellers-chart.component";
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ScheduleComponent,
    TravellersChartComponent
  ],
  imports: [SharedModule],
  exports: [ScheduleComponent],
})
export class ScheduleModule {}
