import { Component } from '@angular/core';
import {
  BookingDTO,
  DefaultService,
  LogOrderDTO,
  OrderDTO,
  OrderItemDTO,
  PaymentDTO,
  PaymentInvoiceDTO,
} from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';
import { Store } from '@ngxs/store';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';
import { Router } from '@angular/router';
import {
  CancelBooking,
  GetBooking,
  UpdateQtyPersonsCheckedOff,
} from 'src/app/core/states/bookings/booking.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent {
  booking?: BookingDTO;
  order?: OrderDTO;
  features?: OrderItemDTO[];
  invoice?: PaymentInvoiceDTO;
  emailMsg?: string;
  emailSent?: boolean;
  loading = false;
  paymentMethods: string[] = [];
  unfinishedPayments?: PaymentDTO[];
  orderLog?: LogOrderDTO[];

  pickedUpCheckbox = new FormControl<boolean>(false);
  droppedOffCheckbox = new FormControl<boolean>(false);

  private onDestroy$ = new Subject<void>();

  constructor(
    private dateService: DateService,
    private store: Store,
    private defaultService: DefaultService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.booking === undefined) {
      const id = this.router.url.split('/')[2];
      this.getBooking(+id);
    } else this.getBooking(this.booking.bookingId);

    this.store
      .select(BookingsState.currentBooking)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        if (v) {
          this.booking = v;

          this.pickedUpCheckbox.setValue(
            this.booking.qtyPersonsPickedUp !== undefined &&
              this.booking.qtyPersonsPickedUp === this.booking.qtyPersons
          );
          this.droppedOffCheckbox.setValue(
            this.booking.qtyPersonsDroppedOff !== undefined &&
              this.booking.qtyPersonsDroppedOff === this.booking.qtyPersons
          );
        }
      });

    this.emailMsg = undefined;
    this.emailSent = undefined;
  }

  ngAfterViewInit() {
    /* Listen to dropped off changes */
    this.droppedOffCheckbox.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        value !== null && this.handlePickedUpOrDroppedOf(value, 'droppedOff');
      });

    /* Listen to picked up changes */
    this.pickedUpCheckbox.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        value !== null && this.handlePickedUpOrDroppedOf(value, 'pickedUp');
      });
  }

  handlePickedUpOrDroppedOf(
    value: boolean,
    type: 'droppedOff' | 'pickedUp'
  ): void {
    const qtyPersonChecked =
      type === 'droppedOff'
        ? this.booking?.qtyPersonsDroppedOff
        : this.booking?.qtyPersonsPickedUp;

    if (value === true) {
      if (this.booking?.qtyPersons !== qtyPersonChecked) {
        this.booking?.bookingId &&
          this.booking.qtyPersons !== undefined &&
          this.store
            .dispatch(
              new UpdateQtyPersonsCheckedOff(
                type === 'droppedOff' ? 'departure' : 'arrival',
                this.booking.bookingId,
                this.booking.qtyPersons
              )
            )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => this.getBooking(this.booking?.bookingId));
      }
    } else {
      /* Confirm deselect */
      if (
        this.booking?.qtyPersons !== undefined &&
        this.booking.qtyPersons === qtyPersonChecked
      ) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: `Är du säker på att du vill ta bort markering för ${
              type === 'droppedOff' ? 'lämnad' : 'hämtad'
            }?`,
            message: [
              `Bokningen kommer stå som ej ${
                type === 'droppedOff' ? 'lämnad' : 'hämtad'
              }.`,
            ],
            yesBtnText: 'Ja',
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((res) => {
            if (res) {
              this.booking?.bookingId &&
                this.store
                  .dispatch(
                    new UpdateQtyPersonsCheckedOff(
                      type === 'droppedOff' ? 'departure' : 'arrival',
                      this.booking.bookingId,
                      0
                    )
                  )
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(() => this.getBooking(this.booking?.bookingId));
            } else {
              type === 'droppedOff'
                ? this.droppedOffCheckbox.setValue(true)
                : this.pickedUpCheckbox.setValue(true);
            }
          });
      }
    }
  }

  getBooking(id?: number): void {
    id &&
      this.store
        .dispatch(new GetBooking(id))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: () =>
            this.defaultService
              .getOrdersAdmin(undefined, 1, undefined, undefined, undefined, id)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((order) => {
                this.order = order.content?.[0];
                this.features = this.order?.orderItems?.filter(
                  (item) =>
                    item.orderItemType === 'FEATURE' &&
                    item.booking?.bookingId &&
                    item.booking.bookingId === id
                );

                this.invoice = this.order?.payments?.find(
                  (payment) => payment.paymentInvoice && payment.paymentInvoice
                )?.paymentInvoice;
                this.paymentMethods = [
                  ...new Set(
                    this.order!.payments?.map(
                      (item) => item.paymentMethod!.label!
                    )
                  ),
                ];

                /* Get order history */
                this.order?.orderId &&
                  this.defaultService
                    .getOrderLog(this.order.orderId)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((orderLog) => {
                      this.orderLog = orderLog;
                    });

                /* Check if order has unfinished payments */
                this.hasUnfinishedPayments();
              }),
          error: (error) =>
            console.error('Something went wrong fetching booking: ', error),
        });
  }

  formatDate(date: string | Date | undefined): string {
    return date ? this.dateService.formatDate(date) : '';
  }

  formatTime(date: string | Date | undefined): string {
    return date ? this.dateService.formatTime(date) : '';
  }

  hasUnfinishedPayments(): void {
    this.order?.orderId &&
      this.defaultService
        .getUnfinishedPayments(this.order?.orderId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (value) => {
            this.unfinishedPayments = value;
          },
          error: (error) =>
            console.error('Error fetching unfinished payments: ', error),
        });
  }

  navBack() {
    this.router.navigate(['hem']);
  }

  editBooking() {
    // open single page view
    this.router.navigate(['redigera-bokning', this.booking?.bookingId]);
  }

  deleteBooking() {
    let bookingItem = this.order?.orderItems?.find(
      (i) => i.booking?.bookingId == this.booking?.bookingId
    );

    /* Check if all associated order items are refunded/not paid */
    const allAssociatedItemsRefunded = this.order?.orderItems
      ?.filter(
        (item) =>
          item.orderItemType === 'FEATURE' &&
          item.booking?.bookingId === this.booking?.bookingId
      )
      .every((featureItem) => featureItem.amountPaid === 0);

    /* Ask to delete if nothing left to refund */
    if (bookingItem?.amountPaid === 0 && allAssociatedItemsRefunded) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Är du säker på att du vill ta bort bokningen?',
          message: [
            'Bokningen kommer att försvinna från hämt- och bokningslistan, men kommer fortfarande att synas i rapporter.',
          ],
          yesBtnText: 'Avboka',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          if (res) {
            this.store
              .dispatch(new CancelBooking(this.booking?.bookingId!))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.router.navigate(['/hem']);
              });
          }
        });
    } else {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Bokningen och/eller eventuell tvätt är inte helt återbetald!',
          message: [
            'För att kunna avboka måste bokning samt eventuell tvätt vara återbetalt.',
          ],
          yesBtnText: 'OK',
        },
      });
    }
  }

  isCancelled(): boolean {
    return this.booking?.bookingStatus === 'CANCELLED' || false;
  }

  sendEmail() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill skicka bokningsbekräftelse till ${this.booking?.name}?`,
        message: [
          `Kunden kommer att få en bokningsbekräftelse till den angivna e-postadressen och det angivna telefonnumret i bokningen.`,
        ],
        yesBtnText: 'Skicka',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          if (this.booking?.bookingId) {
            this.loading = true;
            this.emailMsg = undefined;
            this.defaultService
              .sendBookingConfirmationAdmin(this.booking?.bookingId)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe({
                next: () => {
                  this.emailSent = true;
                  this.emailMsg = 'Bokningsbekräftelse skickat!';
                  this.loading = false;
                },
                error: () => {
                  this.emailSent = false;
                  this.emailMsg =
                    'Det gick inte att skicka bokningsbekräftelsen, kontrollera e-postadressen och telefonnumret.';
                  this.loading = false;
                },
              });
          } else {
            this.emailSent = false;
            this.emailMsg =
              'Det gick inte att skicka bokningsbekräftelsen, ladda om sidan och försök igen.';
          }
        }
      });
  }

  showOrder(id?: number) {
    id !== undefined && this.router.navigate([`/order/${id}`]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
