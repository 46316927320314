import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() isAdmin?: boolean = false;
  description: string = '';
  isProd = true;

  ngOnInit() {
    this.description = environment.description;
    this.isProd = environment.isProd;
  }
}
