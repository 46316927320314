import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { AvailabilityDTO, DefaultService, ResourceDTO } from 'parking-sdk';
import { AddAvailability, DeleteAvailability, UpdateAvailability } from 'src/app/core/states/availabilities/availabilities.actions';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DateService } from 'src/app/core/services/date.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-parking-lot-edit',
  templateUrl: './parking-lot-edit.component.html',
  styleUrls: ['./parking-lot-edit.component.scss']
})
export class ParkingLotEditComponent implements OnInit {
  editedAvailability: AvailabilityDTO = { availabilityId: undefined, availabilityType: 'QTY_PER_DAY', resource: undefined, mainFeature: undefined, validFrom: undefined, validTo: undefined, qty: undefined };
  availableResources?: ResourceDTO[];
  selectedResource?: ResourceDTO;
  invalidFormMessage?: string;

  editForm = new FormGroup({
    qty: new FormControl(),
    resource: new FormControl(),
    validFrom: new FormControl(),
    validTo: new FormControl()
  })

  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ParkingLotEditComponent>,
    private defaultService: DefaultService,
    private store: Store,
    private dateService: DateService,
    @Inject(MAT_DIALOG_DATA) private data?: {availability: AvailabilityDTO},
    ){ 
      if(this.data?.availability) this.editedAvailability = {...this.data.availability};
      this.editForm.controls.qty.setValue(this.editedAvailability.qty);
      this.editForm.controls.resource.setValue(this.editedAvailability.resource?.resourceId);
      this.editForm.controls.validFrom.setValue(this.editedAvailability.validFrom);
      this.editForm.controls.validTo.setValue(this.editedAvailability.validTo);
      this.selectedResource = this.editedAvailability.resource
    }

  ngOnInit(){
    this.defaultService.getResources().pipe(takeUntil(this.onDestroy$)).subscribe(resources => {
      this.availableResources = resources; 
    })  
    
    this.editForm.get('qty')?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.invalidFormMessage = undefined);
    this.editForm.get('validFrom')?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.invalidFormMessage = undefined);
    this.editForm.get('resource')?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.invalidFormMessage = undefined);
  }

  onSelectChange(){
    if(this.availableResources){
      this.selectedResource = this.availableResources.find(resource => resource.resourceId === Number(this.editForm.controls.resource.value));    
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onDoneClick() {
    this.editedAvailability = {
      ...this.editedAvailability,
      qty: this.editForm.controls.qty.value,
      resource: this.selectedResource,
      validFrom: this.editForm.controls.validFrom.value,
      validTo: this.editForm.controls.validTo.value,
    }
    if(this.editedAvailability.availabilityId){
      this.store.dispatch(new UpdateAvailability(this.editedAvailability.availabilityId, this.editedAvailability))
      this.dialogRef.close();
    } else if (this.editedAvailability.qty
        && this.editedAvailability.validFrom
        && this.editedAvailability.resource) {
        this.store.dispatch(new AddAvailability(this.editedAvailability))
        this.dialogRef.close();
    } else {
      this.invalidFormMessage = 'Ett eller flera fält är inte korrekt ifyllda.'
    }
  }

  onRemoveClick() {
    const validFromFormatedDate = this.editedAvailability.validFrom ? this.dateService.formatDate(this.editedAvailability.validFrom) : undefined;
    const validToFormatedDate = this.editedAvailability.validTo ? this.dateService.formatDate(this.editedAvailability.validTo) : 'Tillsvidare';

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill ta bort "${this.selectedResource?.label} - ${this.editedAvailability.qty} platser"?`,
        message: [
          `Period: ${validFromFormatedDate} - ${validToFormatedDate}.`,
          `Det kommer inte gå att återställa tillgängligheten`
        ]
      }
    })

    confirmDialog.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if(result && this.editedAvailability.availabilityId){
         this.store.dispatch(new DeleteAvailability(this.editedAvailability.availabilityId))
        
         this.dialogRef.close();
        }
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
