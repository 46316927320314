import { NgModule } from '@angular/core';
import { OccupancyReportComponent } from './occupancy-report.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RelyStatisticsModule } from 'rely-statistics-sdk';



@NgModule({
  declarations: [OccupancyReportComponent],
  imports: [SharedModule, RelyStatisticsModule],
  exports: [OccupancyReportComponent]
})
export class OccupancyReportModule { }
