<h3 mat-dialog-title>Återköp Klarna</h3>

<div mat-dialog-content>
  <p>Maxbelopp för återbetalning: {{data.maxRefund}}</p>

  <div class="input">
    <mat-form-field>
      <mat-label>Summa att återbetala</mat-label>
      <input matInput type="number" autocomplete="off" [formControl]="refundControl" min="0" max="{{data.maxRefund}}">
      <mat-error>Belopp får inte överstiga det totala orderbeloppet</mat-error>
    </mat-form-field>
  </div>

  <div class="input">
    <mat-form-field>
      <mat-label>Anledning till återköp</mat-label>
      <input matInput type="text" autocomplete="off" [formControl]="refundComment">
      <mat-error>Kommentar krävs</mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="closeDialog()">Avbryt</button>
    <button mat-button class="button" (click)="handleRefund()">Genomför återköp</button>
  </div>
</div>