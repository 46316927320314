<div class="content" *ngIf="currentOrder &&
    currentOrder?.orderId &&
    router.url !== '/hantera-betalning' &&
    router.url !== '/aterbetalning' &&
    !router.url.includes('order')">
  <h3>OBS! Hantera pågående order</h3>

  <mat-accordion>
    <mat-expansion-panel expanded="true" class="simple">
      <mat-expansion-panel-header>
        <mat-panel-title class="title"><strong>Order #{{currentOrder.orderId}}</strong></mat-panel-title>
      </mat-expansion-panel-header>

      <ul>
        <li *ngFor="let item of currentOrder.orderItems">
          <p *ngIf="item.orderItemType === 'FEATURE'">{{item.feature?.name}}</p>
          <p *ngIf="item.orderItemType === 'RESOURCE'">
            <mat-icon>directions_car</mat-icon>
            <!-- Can add (click)="editBooking(item.booking!)", but needs to be tested thoroughly -->
            <a>{{item.booking?.registrationNumber}}</a>
          </p>
          <p *ngIf="item.orderItemType === 'PREPAIDTICKET'">{{item.prepaidTicket?.name}} (Abonnemang)</p>
          <div class="amount">
            <p>{{item.amount}} kr</p>
            <button class="removeButton" mat-icon-button
              (click)="removeOrderItem(item)"><mat-icon>clear</mat-icon>
            </button>
          </div>
        </li>
        <li class="total"><span>Total: </span><span>{{currentOrder.totalAmount}} kr</span></li>
      </ul>

    </mat-expansion-panel>
  </mat-accordion>

  <div class="buttons">
    <button class="button success slim handleButton" (click)="removeOrder()">Avbryt</button>
    <button class="button success slim handleButton" routerLink="../hantera-betalning">Hantera betalning</button>
  </div>
</div>