<div class="content-wrapper">
  <button mat-flat-button (click)="navBack()" class="back-button">
    <mat-icon>arrow_back</mat-icon>
    Till startsidan
  </button>
  <div class="handle" *ngIf="booking && booking.bookingStatus !== 'CANCELLED'">
    <mat-icon class="edit" title="redigera" (click)="editBooking()">edit</mat-icon>
    <mat-icon class="delete" title="radera" (click)="deleteBooking()">delete</mat-icon>
  </div>


  <section class="summary" *ngIf="booking">
    <h2>
      <span>
        <mat-icon>directions_car</mat-icon>
        <span>{{ booking.registrationNumber }} {{booking.bookingStatus === 'CANCELLED' ? '- AVBOKAD' : ''}}</span>
      </span>
    </h2>

    <span class="car-type" *ngIf="booking.vehicleType?.label || booking.engineType?.label">
      <span class="connect-to-car"></span>
      {{booking.vehicleType?.label === 'SUV' ? 'SUV |' : ''}}
      {{booking.engineType?.label || ''}}
    </span>

    <div class="parking-and-flight-wrapper">
      <h4>Parkering starttid</h4>
      <div class="departure">

        <div class="date-wrapper">
          <span class="date">
            <mat-icon>event</mat-icon>
            <span>{{
              booking.departureDate
              ? formatDate(booking.departureDate)
              : 'datum ej tillgängligt'
              }}
            </span>
          </span>

          <span class="date">
            <mat-icon>access_time</mat-icon>
            <span>{{
              booking.departureDate ? formatTime(booking.departureDate) : '--:--'
              }}</span>
          </span>
        </div>

        <app-flight-information [flight]="booking.departureFlight" [flightInput]="booking.departureFlightNumber"
          [destination]="booking.departureFlight?.arrivalAirport" [travelHomeFrom]="undefined"
          [scheduledDate]="booking.departureFlight?.scheduledDepartureDate"
          [estimatedDate]="booking.departureFlight?.estimatedDepartureDate"
          [actualDate]="booking.departureFlight?.actualDepartureDate">
        </app-flight-information>

        <div class="passenger-status">
          <span>Lämnade</span>
          <mat-checkbox [formControl]="droppedOffCheckbox" color="primary"></mat-checkbox>
          <span
            *ngIf="booking.qtyPersonsDroppedOff !== booking.qtyPersons && (booking.qtyPersonsDroppedOff || 0) > 0">{{booking.qtyPersonsDroppedOff}}
            /
            {{booking.qtyPersons}}
          </span>
        </div>
      </div>

      <h4>Parkering sluttid</h4>
      <div class="arrival">

        <div class="date-wrapper">
          <span class="date">
            <mat-icon>event</mat-icon>
            <span>{{
              booking.arrivalDate
              ? formatDate(booking.arrivalDate)
              : 'datum ej tillgängligt'
              }}</span>
          </span>
          <span class="date">
            <mat-icon>access_time</mat-icon>
            <span>{{
              booking.arrivalDate ? formatTime(booking.arrivalDate) : '--:--'
              }}
            </span>
          </span>
        </div>

        <app-flight-information [flight]="booking.arrivalFlight" [flightInput]="booking.arrivalFlightNumber"
          [travelHomeFrom]="booking.travelHomeFrom" [destination]="undefined"
          [scheduledDate]="booking.arrivalFlight?.scheduledArrivalDate"
          [estimatedDate]="booking.arrivalFlight?.estimatedArrivalDate"
          [actualDate]="booking.arrivalFlight?.actualArrivalDate">
        </app-flight-information>

        <div class="passenger-status">
          <span>Hämtade</span>
          <mat-checkbox [formControl]="pickedUpCheckbox" color="primary"></mat-checkbox>
          <span *ngIf="booking.qtyPersonsPickedUp !== booking.qtyPersons 
          && ((booking.qtyPersonsPickedUp || 0) > 0 )">
            {{booking.qtyPersonsPickedUp}} / {{booking.qtyPersons}}
          </span>
        </div>
      </div>
    </div>

    <div class="highlight">
      <span title="Betalningsstatus">
        <mat-icon>credit_card</mat-icon>
        <span [ngClass]="{ nopayment: !invoice && order?.paymentStatus?.paymentStatusId === 'UNPAID' }">{{ invoice ?
          'Faktura' :
          order?.paymentStatus?.label || 'okänt' }}</span>
      </span>
      <span title="Plats">
        <mat-icon>place</mat-icon>
        <span>{{ booking.resource?.label }}</span>
      </span>
      <span title="Resenärer">
        <mat-icon>people</mat-icon>
        <span>{{ booking.qtyPersons }}</span>
      </span>
    </div>

    <div class="feature-section" *ngIf="features?.length">
      <h4>Tillval</h4>
      <ul>
        <li *ngFor="let feature of features">
          <span> {{ feature.feature?.name }}</span>
          <span class="feature-amount">
            {{ feature.amount | number : '1.0-0' }} kr</span>
        </li>
      </ul>
    </div>

    <div class="comment" *ngIf="booking.comment">
      <h4>Kommentar</h4>
      <p>{{ booking.comment }}</p>
    </div>

    <div class="bullet-items">
      <p>
        <span [ngClass]="{
          'mat-icon-bullet': true,
          inactive: booking.handLuggageOnly
        }">
          <mat-icon>
            {{!booking.handLuggageOnly ? 'checkmark' : 'close' }}</mat-icon>
        </span>
        <span>Incheckat bagage</span>
      </p>
      <p>
        <span [ngClass]="{
          'mat-icon-bullet': true,
          inactive: !booking.childSafetySeat
        }">
          <mat-icon>{{booking.childSafetySeat ? 'checkmark' : 'close'}}</mat-icon>
        </span>
        <span>Bilbarnstol</span>
      </p>
    </div>


  </section>

  <section class="info-section" *ngIf="booking">
    <div>
      <h3>Bokningsinformation</h3>
      <ul>
        <li>
          <span>Bokningsnummer: </span>
          <span class="value-to-end">{{ booking.qr }}</span>
        </li>
        <li>
          <span>Skapad:</span>
          <span class="value-to-end">{{ formatDate(booking.createdDate) || 'okänt' }}
            {{ formatTime(booking.createdDate) || '' }}
          </span>
        </li>
        <li>
          <span>Skapad av:</span>
          <span class="value-to-end">{{ booking.createdBy?.name || booking.createdBy?.username || 'okänt' }}</span>
        </li>
        <li>
          <span>Uppdaterad:</span>
          <span class="value-to-end">{{ formatDate(booking.updatedDate) || 'ej uppdaterad' }}
            {{ formatTime(booking.updatedDate) || '' }}</span>
        </li>
        <li>
          <span>Uppdaterad av:</span>
          <span class="value-to-end">
            {{ booking.updatedBy?.name || booking.updatedBy?.username || 'okänt' }}
          </span>
        </li>
        <li *ngIf="booking.prepaidTicket">
          <span>Utnyttjat abonnemang: </span>
          <span class="value-to-end">{{ booking.prepaidTicket.name }} ({{
            booking.prepaidTicket.prepaidTicketId
            }})</span>
        </li>
      </ul>
    </div>

    <div class="order-info">
      <h3>Orderinformation</h3>
      <ul *ngIf="order">
        <li>
          <span>Ordernummer: </span>
          <a (click)="showOrder(order.orderId)" class="value-to-end"><span>{{ order.orderId || '-' }}</span><mat-icon
              class="arrow">chevron_right</mat-icon></a>
        </li>
        <li>
          <span>Status: </span>
          <span class="value-to-end">
            {{!order.payments?.length &&
            order.orderStatus &&
            order.orderStatus !== 'COMPLETED' ? 'ORDER EJ BEHANDLAD. VÄLJ BETALNINGSMETOD.': 'Skapad'}}
          </span>
        </li>
        <li>
          <span>Betalningsmetod: </span>
          <span class="value-to-end" *ngIf="!order?.payments?.length">
            Betala senare
          </span>
          <span class="value-to-end" *ngFor="let payment of paymentMethods">
            {{ payment }}
          </span>
        </li>
        <li class="invoice-wrapper" *ngIf="invoice">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>Fakturauppgifter</span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ul>
              <li>
                <span>Organisations-/personnummer: </span>
                <span class="value-to-end">
                  {{invoice.organizationNumber}}
                </span>
              </li>
              <li><span>Referens: </span><span class="value-to-end">{{ invoice.reference || '-' }}</span></li>
              <li><span>Namn: </span><span class="value-to-end">{{ invoice.name }}</span></li>
              <li><span>E-post kontakt: </span><span class="value-to-end">{{ invoice.email }}</span></li>
              <li><span>Telefonnummer: </span><span class="value-to-end">{{ invoice.phone }}</span></li>
              <li>
                <span>E-faktura: </span>
                <span class="value-to-end">{{ invoice.invoiceEmail || 'ingen' }}</span>
              </li>
              <li><span>Adress: </span><span class="value-to-end">{{ invoice.address }}</span></li>
              <li><span>Postnummer: </span><span class="value-to-end">{{ invoice.zip }}</span></li>
              <li><span>Stad: </span><span class="value-to-end">{{ invoice.city }}</span></li>
              <li><span>Land: </span><span class="value-to-end">{{ invoice.country }}</span></li>
            </ul>
          </mat-expansion-panel>
        </li>
        <li *ngIf="!invoice">
          <span> Betalningsstatus: </span>
          <span class="value-to-end">{{ order.paymentStatus?.label || 'okänt' }}</span>
        </li>
        <li class="order-summary-title">Ordersummering:</li>
        <app-order-summary [order]="order" [currentBooking]="booking"
          [hasUnfinishedPayments]="(unfinishedPayments?.length || 0) > 0" (onGetBooking)="getBooking($event)">
        </app-order-summary>
      </ul>
    </div>

    <div>
      <h3>Kontaktinformation</h3>
      <ul>
        <li>
          <span> Namn: </span>
          <span class="value-to-end">{{ booking.name }}</span>
        </li>
        <li>
          <span> Telefonnummer: </span>
          <span class="value-to-end">{{ booking.phone }}</span>
        </li>
        <li>
          <span> E-post: </span>
          <span class="value-to-end">{{ booking.email }}</span>
        </li>
        <li>
          <span>Nyhetsbrev: </span>
          <span class="value-to-end">{{ booking.acceptNewsletter ? 'Ja' : 'Nej' }}</span>
        </li>
      </ul>

      <div class="email-button-wrapper">
        <button [ngClass]="{'button': true, 'slim': true, 'loading': loading}" [disabled]="isCancelled()"
          (click)="sendEmail()">
          Skicka bokningsbekräftelse
          <mat-spinner *ngIf="loading" diameter="25" color="accent"></mat-spinner>
        </button>
        <p [ngClass]="{'success': emailSent, 'failed': !emailSent}" *ngIf="emailMsg">{{emailMsg}}</p>
      </div>
    </div>
  </section>
</div>

<div class="history">
  <h3>Historik</h3>
  <div class="empty-content" *ngIf="!orderLog?.length">
    <p>Ingen historik</p>
  </div>
  <app-history [historyLog]="orderLog"></app-history>
</div>