import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DefaultService, PartnerDTO } from 'parking-sdk';
import { delay, pipe, Subject, takeUntil } from 'rxjs';
import { SetCurrentPartner } from 'src/app/core/states/partner/partner.action';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss'],
})
export class NavbarAdminComponent {
  partners?: PartnerDTO[];
  partnerIsActiveNow = false; // Can't user routerLinkActive in the partner menu. This is a workaround.
  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.defaultService
      .findPartners()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((partners) => {
        this.partners = partners;
      });
  }

  setCurrentPartner(partner: PartnerDTO) {
    partner &&
      this.store
        .dispatch(new SetCurrentPartner(partner))
        .pipe(takeUntil(this.onDestroy$), delay(0))
        .subscribe(() => {
          this.router.navigate(['/partnerportalen/']);
        });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
