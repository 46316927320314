import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { ResetOrderTransState } from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';

@Component({
  selector: 'app-pax-payment-status',
  templateUrl: './pax-payment-status.component.html',
  styleUrls: ['./pax-payment-status.component.scss'],
})
export class PaxPaymentStatusComponent {
  displayStatus?: string;
  @Output() finished: EventEmitter<any> = new EventEmitter();
  private onDestroy$ = new Subject<void>();
  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(OrdersState.status)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status) => {
        if (status.displayData) {
          this.displayStatus =
            status.displayData[status.displayData.length - 1].description!;

          if (status.transCancelled) {
            /* Emit output cancelled */
            this.finished.emit({cancelled: true})
          } else if (status.transApproved) {
            /* Emit output approved */
            this.finished.emit({approved: true})
          } else if (status.transDeclined) {
            /* Emit output declined */
            this.finished.emit({declined: true})
          } else if (status.transEnded) {
            /* Emit output ended */
            this.finished.emit({ended: true})
          }
        }

        if (status.errorText === 'no connection') {
          this.finished.emit({noConnection: true})
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
