<section>
    <h3>Lindskrog Parkering, Arlanda</h3>

    <!-- Org information -->
    <div class="contact-info">
        <p>Org. Nr: 556538-3691</p>
        <p>Tel: 08-59361285</p>
    </div>

    <h4>Kassalådan öppnad</h4>
    <div class="content-wrapper">
        <ul>
            <li><strong>Datum:</strong> {{date}} {{time}}</li>
            <li><strong>Användare:</strong> {{user?.name || user?.username || 'okänt'}}</li>
        </ul>

        <div class="comment">
            <h5>Kommentar</h5>
            <p>
                "{{comment}}"
            </p>
        </div>

        <span class="underline"></span>
        <span class="signature"><i>Namnteckning</i></span>
    </div>

</section>