<h5>
  Flight
  {{ flight ? flight.flightNumber : flightInput || '(flightnummer saknas)' }}
</h5>
<p class="flex-wrapper">
  <span>{{ destination ? 'Destination: ' : 'Reser hem från: ' }}</span>
  <span class="city-flightnumber">
    {{ destination || travelHomeFrom || 'okänt' }}
  </span>
</p>
<div class="flex-wrapper">
  <span>{{ destination ? 'Avgångstid:' : 'Ankomsttid:' }}</span>

  <!-- Scheduled date -->
  <p [ngClass]="{ updated: estimatedDate || actualDate }">
    <span>
      {{ flight ? formatDate(scheduledDate) : 'okänt' }}
    </span>
    <span class="time">{{ flight ? formatTime(scheduledDate) : '--:--' }}</span>
  </p>

  <!-- Separator -->
  <span *ngIf="actualDate || estimatedDate">|</span>

  <!-- New date -->
  <p *ngIf="actualDate || estimatedDate" class="new-date">
    <span>
      {{
        flight ? formatDate(actualDate) || formatDate(estimatedDate) : 'okänt'
      }}
    </span>
    <span class="time">
      {{
        flight ? formatTime(actualDate) || formatTime(estimatedDate) : '--:--'
      }}
    </span>
  </p>
</div>
