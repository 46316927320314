<h2 class="title">Inställningar för SMS</h2>
<p>Ändra hälsningsfrasen som står före och meddelandet som står efter bokningsinformationen i SMSbokningsbekräftelsen.</p>
<div class="sms-settings-wrapper">
    <article class="sms-confirm-msg">
        <h3>Ändra bekräftelsemeddelande</h3>
        <div class="sms-msg-wrapper">
            <form class="sms-confirm-form" spellcheck="false">
                <h4>Inledande hälsning</h4>
                <mat-form-field class="sms-confirm-input">
                    <mat-label>Hälsningsfras</mat-label>
                    <input matInput [formControl]="preText" maxlength="80" />
                    <mat-hint align="end">{{preText.value.length}} tecken</mat-hint>
                </mat-form-field>
                <div class="sms-confirm-buttons" >
                    <button mat-flat-button (click)="reset('PRE')" *ngIf="preTextChanged">Avbryt</button>
                    <button mat-raised-button color="primary" (click)="save('PRE')" [disabled]="!preTextChanged">Spara</button>
                </div>
            </form>
            <form class="sms-confirm-form" spellcheck="false">
                <h4>Avslutande Meddelande</h4>
                <mat-form-field class="sms-confirm-textarea">
                    <mat-label>Meddelande</mat-label>
                    <textarea matInput cols="50" rows="5" maxlength="160" [formControl]="postText"></textarea>
                    <mat-hint align="end">{{postText.value.length}} tecken</mat-hint>
                </mat-form-field>
                <div class="sms-confirm-buttons" >
                    <button mat-flat-button (click)="reset('POST')" *ngIf="postTextChanged">Avbryt</button>
                    <button mat-raised-button color="primary" (click)="save('POST')" [disabled]="!postTextChanged">Spara</button>
                </div>
            </form>
        </div>
    </article>
    <aside class="sms-preview">
        <h4>Förhandsgranska SMS: </h4>
        <div class="preview-part-wrapper">
            <p class="sms-preview-part" [innerHTML]="previewData.text"></p>
            <span class="sms-preview-data">(Antal tecken {{previewData.length}}&#42;. Antal SMS: {{previewData.numOfSMS}}<span *ngIf="previewData.numOfSMS > 1">&#42;&#42;</span>)</span>
        </div>
        <p class="sms-preview-disclaimer" *ngIf="previewData.numOfSMS > 1">&#42; Antal tecken är ungefärliga och kan komma att ändras i det sms som skickas till kund beroende på längd på namn, epost m.m.</p>
        <p class="sms-preview-disclaimer" *ngIf="previewData.numOfSMS > 1">&#42;&#42; Antal SMS är uppskattade utifrån 153 tecken / sms</p>
    </aside>
</div>
