<mat-accordion [multi]="true" *ngIf="historyLog?.length">
    <mat-expansion-panel *ngFor="let item of historyLog">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>{{item.action || 'okänd händelse'}}</span>
          <span>{{ formatDate(item.createdDate) || 'okänt' }}
            {{ formatTime(item.createdDate) || '' }}</span>
          <span>Av: {{item.createdBy?.name || item.createdBy?.username || 'okänd användare'}}</span>
         
          <span class="amount-history">
            {{item.amount || '-'}} kr
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="booking-content info-section">
        <ul>
          <li>
            <span>Händelse-ID: </span>
            <span class="value-to-end">{{ item.logOrderId || '-' }}</span>
          </li>
          <li>
            <span>Parkering starttid:</span>
            <span class="value-to-end">{{ formatDate(item.departureDate) || '-' }}
              {{ formatTime(item.departureDate) || '' }}
            </span>
          </li>
          <li>
            <span>Parkering sluttid:</span>
            <span class="value-to-end">{{ formatDate(item.arrivalDate) || '-' }}
              {{ formatTime(item.arrivalDate) || ''}}</span>
          </li>
          <li>
            <span>Flightnr. utresa</span>
            <span class="value-to-end">{{ item.departureFlightNumber || '-'}}</span>
          </li>
          <li>
            <span>Flightnr. hemresa:</span>
            <span class="value-to-end">
              {{ item.arrivalFlightNumber || '-' }}
            </span>
          </li>
          <li>
            <span>Reser hem från: </span>
            <span class="value-to-end">{{ item.travelHomeFrom || '-'}}</span>
          </li>
          <li>
            <span>Plats: </span>
            <span class="value-to-end">{{ item.resourceName || '-'}}</span>
          </li>
          <li>
            <span>Tillval: </span>
            <span class="value-to-end">{{ item.features || '-' }}</span>
          </li>
          <li>
            <span>Incheckat bagage: </span>
            <span class="value-to-end">{{ !item.handluggageOnly ? 'Ja' : 'Nej' }}</span>
          </li>
          <li>
            <span>Bilbarnstol: </span>
            <span class="value-to-end">{{ item.childSafetySeat ? 'Ja' : 'Nej' }}</span>
          </li>
          <li>
            <span>Bokningsstatus: </span>
            <span class="value-to-end">{{ item.bookingStatus || '-' }}</span>
          </li>
          <li class="comment">
            <span>Kommentar: </span>
            <span class="value-to-end">{{ item.comment || '-' }}</span>
          </li>
        </ul>

        <ul>
          <li>
            <span>Namn: </span>
            <span class="value-to-end">{{ item.name || '-' }}</span>
          </li>
          <li>
            <span>Telefonnummer: </span>
            <span class="value-to-end">{{ item.phone || '-' }}</span>
          </li>
          <li>
            <span>E-post: </span>
            <span class="value-to-end">{{ item.email || '-' }}</span>
          </li>
          <li>
            <span>Nyhetsbrev: </span>
            <span class="value-to-end">{{ item.acceptNewsletter ? 'Ja' : 'Nej' }}</span>
          </li>
          <li>
            <span>Antal resenärer: </span>
            <span class="value-to-end">{{ item.qtyPersons || '-' }}</span>
          </li>
          <li>
            <span>Registreringsnummer: </span>
            <span class="value-to-end">{{ item.registrationNumber || '-' }}</span>
          </li>
          <li>
            <span>Bilstorlek: </span>
            <span class="value-to-end">{{ item.vehicleType || '-' }}</span>
          </li>
          <li>
            <span>Motor: </span>
            <span class="value-to-end">{{ item.engineType || '-' }}</span>
          </li>
          <li>
            <span>Pris: </span>
            <span class="value-to-end">{{ item.amount ? item.amount + ' kr' : '-' }}</span>
          </li>
          <li>
            <span>Betalat: </span>
            <span class="value-to-end">{{ item.amountPaid ? item.amountPaid + ' kr' : '-' }} </span>
          </li>
          <li>
            <span>Rabatt: </span>
            <span class="value-to-end">{{ item.discount ? '-' + item.discount + ' kr' : '-' }}</span>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
  </mat-accordion>