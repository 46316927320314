<h3 mat-dialog-title *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && data.refund">Återköp
</h3>
<h3 mat-dialog-title *ngIf="!showChooseTerminal && !transactionStarted && !data.refund">Belopp att betala</h3>
<h3 mat-dialog-title *ngIf="showChooseTerminal">Välj betalterminal</h3>
<h3 mat-dialog-title *ngIf="transactionStarted && !transactionFinished">Betalningsstatus</h3>

<div mat-dialog-content class="content">
  <!-- Choice of refund method -->
  <mat-button-toggle-group class="toggle"
    *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && data.refund"
    #group="matButtonToggleGroup" (change)="changeRefundMethod(group.value)" value="card">
    <mat-button-toggle value="card">Kort</mat-button-toggle>
    <mat-button-toggle value="bank">Banköverföring</mat-button-toggle>
  </mat-button-toggle-group>

  <p *ngIf="data.refund && !transactionStarted && !showChooseTerminal && !transactionFinished">Max summa för
    återbetalning: {{data.maxRefund}} kr</p>
  <p *ngIf="data.refund && transactionFinished">Återköp genomfört</p>

  <div *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && !data.refund" class="input">
    <mat-form-field>
      <mat-label>Belopp</mat-label>
      <input matInput max="{{data.order.amountToPay}}" min="0" [formControl]="paymentControl">
      <mat-error>Max belopp {{data.order.amountToPay}} kr</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && data.refund" class="input">
    <mat-form-field>
      <mat-label>Belopp</mat-label>
      <input matInput max="{{data.maxRefund}}" min="0" [formControl]="refundControl">
      <mat-error>Max belopp {{data.maxRefund}} kr</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && data.refund" class="input">
    <mat-form-field>
      <mat-label>Anledning till återköp</mat-label>
      <input matInput type="text" [formControl]="commentControl" required>
      <mat-error>Kommentar krävs</mat-error>
    </mat-form-field>
  </div>

  <div class="selectTerminal" *ngIf="showChooseTerminal && terminalsFetched">
    <mat-radio-group [formControl]="form">
      <mat-radio-button *ngFor="let terminal of availTerminals" [value]="terminal"
        [checked]="terminal.terminalId === selectedTerminal!.terminalId">{{terminal.name}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Display status message -->
  <div class="paymentStatus" *ngIf="transactionStarted">
    <app-pax-payment-status (finished)="finalStatus($event)"></app-pax-payment-status>
  </div>

  <div mat-dialog-actions class="dialogActions">
    <!-- Cancel button -->
    <button mat-button (click)="cancelPayment(form.value?.terminalId!)"
      *ngIf="transactionStarted && !transactionFinished">Avbryt</button>

    <!-- Close button -->
    <button mat-button *ngIf="!transactionStarted && !transactionFinished" (click)="closeDialog()">Stäng</button>

    <!-- Go to select terminal -->
    <button mat-button  (click)="chooseTerminal()"
      *ngIf="!showChooseTerminal && !transactionStarted  && paymentMethod === 'card'"
      [disabled]="refund && !commentControl.value">
      Nästa
    </button>

    <!-- Handle refund through bank transfer -->
    <button mat-button (click)="bankRefund()"
      *ngIf="!showChooseTerminal && !transactionStarted && !transactionFinished && paymentMethod === 'bank'"
      [disabled]="refund && !commentControl.value">
      Genomför återköp
    </button>

    <!-- Start payment -->
    <button mat-button (click)="startPayment(form.value!)" *ngIf="showChooseTerminal" [disabled]="!selectedTerminal"
      disabled>
      Nästa
    </button>

    <button mat-button *ngIf="transactionFinished" (click)="skipPrint()">Stäng</button>
    <button mat-button *ngIf="transactionFinished && paymentMethod === 'card'" class="button" (click)="receiptPrint()">Skriv ut kvitto</button>
  </div>
</div>