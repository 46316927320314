<!-- Valid token - change password -->
<div *ngIf="validToken">
  <h2>Välj ett nytt lösenord</h2>

  <div>
    <form 
      *ngIf="newPassForm" 
      [formGroup]="newPassForm"
      (ngSubmit)="submit()">
      <mat-form-field>
        <input matInput name="newPass" formControlName="newPass" type="password" placeholder="Nytt lösenord" spellcheck="false" autocomplete="off">
      </mat-form-field>

      <mat-form-field>
        <input matInput name="repeatPass" formControlName="repeatPass" type="password" placeholder="Upprepa lösenord" spellcheck="false" autocomplete="off">
      </mat-form-field>

      <button class="button" [disabled]="!newPassForm.valid" type="submit">Byt lösenord</button>
    </form>
  </div>
</div>

<!-- Invalid token info -->
<div *ngIf="!validToken">
  <h2>Ogiltig länk</h2>

  <p>Någonting gick fel. Antingen har din länk gått ut, eller så är den felaktig. Prova igen.</p>
</div>