import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportTravelerDTO } from 'parking-sdk';
import { LineChartDataset } from 'rely-statistics-sdk';

@Component({
  selector: 'app-travellers-chart',
  templateUrl: './travellers-chart.component.html',
  styleUrls: ['./travellers-chart.component.scss'],
})
export class TravellersChartComponent implements OnChanges {
  @Input() reports!: ReportTravelerDTO[];

  chartData: LineChartDataset[] = [];
  chartTitles: string[] = [];
  arrivalData: number[] = [];
  departureData: number[] = [];
  totalData: number[] = [];
  garageData: number[] = [];

  //chartColors: string[] = ['#769570', '#f6a200', '#978fcd', '#ad2120'];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getReport();
  }

  getReport() {
    this.chartData = [];
    this.chartTitles = [];
    this.arrivalData = [];
    this.totalData = [];
    this.departureData = [];
    this.garageData = [];

    this.reports.forEach((report) => {
      this.chartTitles.push(formatDate(report.date!, 'HH:mm', 'sv-SE'));
      this.arrivalData.push(report.arrivalTravelers!);
      this.departureData.push(report.departureTravelers!);
      this.garageData.push(report.totalGarageCars!);
      this.totalData.push(report.totalTravelers!);
    });

    this.chartData.push(
      { data: this.arrivalData, type: 'line', name: 'Ankommande' },
      { data: this.departureData, type: 'line', name: 'Avresande' },
      { data: this.garageData, type: 'line', name: 'Garagebilar total' },
      { data: this.totalData, type: 'line', name: 'Alla resande' }
    );
  }
}
