<section>
    <h2>Kassa</h2>

    <h3>Öppna kassalåda</h3>
    <form *ngIf="openRegisterForm" [formGroup]="openRegisterForm">
        <mat-form-field>
            <mat-label>Varför öppnades kassalådan?</mat-label>
            <textarea matInput name="comment" formControlName="comment" [spellcheck]="false"></textarea>
        </mat-form-field>
        <button class="button slim" type="submit" (click)="printReceipt()"
            [disabled]="!openRegisterForm.controls['comment'].value">Öppna</button>
    </form>

    <iframe #registerOpenedReceipt frameBorder="0" width="750" height="500" id="registerOpenedReceipt"
        name="registerOpenedReceipt" (load)="onFrameLoad()">
    </iframe>
</section>