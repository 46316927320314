import { NgModule } from '@angular/core';
import { PartnershipComponent } from './partnership.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [PartnershipComponent],
  imports: [SharedModule],
  exports: [PartnershipComponent],
})
export class PartnershipModule {}
