<h2>Alla ordrar</h2>
<form *ngIf="searchAndFilterForm" [formGroup]="searchAndFilterForm">
  <div class="formfield">
    <div class="searchbox-wrapper">
      <input type="search" placeholder="Sök på reg.nr, order.nr, eller tillägg." data-test="search-all-bookings"
        [formControl]="searchInput" spellcheck="false" autocomplete="off" (input)="search()" />

      <div class="filters">
        <mat-radio-group formControlName="paymentStatus" name="paymentStatus">
          <mat-label class="filters-group-label">
            <h5>Betalstatus:</h5>
          </mat-label>
          <mat-radio-button color="primary" value="all">Alla</mat-radio-button>
          <mat-radio-button color="primary" value="UNPAID">KRAV</mat-radio-button>
          <mat-radio-button color="primary" value="PAID">Betald</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group formControlName="paymentMethod" name="paymentMethod">
          <mat-label>
            <h5>Betalmetod:</h5>
          </mat-label>
          <mat-radio-button color="primary" value="all">Alla</mat-radio-button>
          <mat-radio-button color="primary" value="INVOICE">Faktura</mat-radio-button>
          <mat-radio-button color="primary" value="KLARNA">Klarna</mat-radio-button>
          <mat-radio-button color="primary" value="PAX">Kort</mat-radio-button>
          <mat-radio-button color="primary" value="CASH">Kontant</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <mat-form-field>
      <mat-label>Tidsperiod</mat-label>
      <mat-date-range-input [rangePicker]="orderDatePicker">
        <input matStartDate placeholder="Från" name="from" formControlName="from" />
        <input matEndDate placeholder="Till" name="to" formControlName="to" />
      </mat-date-range-input>
      <mat-hint>ÅÅÅÅ-MM-DD - ÅÅÅÅ-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="orderDatePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #orderDatePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
</form>

<mat-paginator [length]="totalOrders" [pageIndex]="pageIndex" [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>

<div class="empty-content" *ngIf="!orders?.length">
  <p>Kan inte hitta order<span *ngIf="searchInput.value"> som matchar "{{searchInput.value}}"</span>.</p>
  <p>Kontrollera att du skrivit rätt registrerings- eller ordernummer och anpassa eventuella filter.</p>
</div>

<table *ngIf="orders && orders.length > 0" mat-table [dataSource]="orders" matSort matSortStart="desc"
  matSortDisableClear (matSortChange)="sortChange($event)">
  <!-- Order number Column -->
  <ng-container matColumnDef="orderNumber">
    <th mat-header-cell *matHeaderCellDef>Order.nr</th>
    <td data-test="order.nr" mat-cell *matCellDef="let element">
      {{ element.orderId }}
    </td>
  </ng-container>

  <!-- Bookings Column -->
  <ng-container matColumnDef="bookings">
    <th mat-header-cell *matHeaderCellDef>Reg.nr</th>
    <td mat-cell *matCellDef="let element">
      <span class="booking" *ngFor="let value of getOrderItems(element, 'RESOURCE')">{{ value.booking.registrationNumber
        + ' ' }}</span>
      <span *ngIf="getOrderItems(element, 'RESOURCE').length <= 0">-</span>
    </td>
  </ng-container>

  <!-- Prepaid ticket Column -->
  <ng-container matColumnDef="prepaid">
    <th mat-header-cell *matHeaderCellDef>Abonnemang</th>
    <td mat-cell *matCellDef="let element">
      <span class="booking" *ngFor="let value of getOrderItems(element, 'PREPAIDTICKET')">{{ value.prepaidTicket.name
        + ' ' }}</span>
      <span *ngIf="getOrderItems(element, 'PREPAIDTICKET').length <= 0">-</span>
    </td>
  </ng-container>

  <!-- Features Column -->
  <ng-container matColumnDef="features">
    <th mat-header-cell *matHeaderCellDef>Tjänster</th>
    <td mat-cell *matCellDef="let element">
      <span *ngFor="let value of getOrderItems(element, 'FEATURE')">{{value.feature.name + ' '}}</span>
      <span *ngIf="getOrderItems(element, 'FEATURE').length <= 0 ">-</span>
    </td>
  </ng-container>

  <!-- Payment status Column -->
  <ng-container matColumnDef="paymentStatus">
    <th mat-header-cell *matHeaderCellDef>Betalningsstatus</th>
    <td mat-cell *matCellDef="let element">
      {{element.paymentStatus.label || '-'}}
    </td>
  </ng-container>

  <!-- Payment method Column -->
  <ng-container matColumnDef="paymentMethod">
    <th mat-header-cell *matHeaderCellDef>Betalningsmetod</th>
    <td mat-cell *matCellDef="let element">
      <span *ngFor="let value of uniquePaymentMethods(element.payments)">{{value + ' '}}</span>
      <span *ngIf="element.payments.length <= 0">-</span>

    </td>
  </ng-container>

  <!-- Created Column -->
  <ng-container matColumnDef="createdDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sortera på datum">Skapad</th>
    <td mat-cell *matCellDef="let element">
      {{formatDate(element.createdDate)}}
    </td>
  </ng-container>

  <!-- Updated Column -->
  <ng-container matColumnDef="updatedDate">
    <th mat-header-cell *matHeaderCellDef>Uppdaterad senast</th>
    <td mat-cell *matCellDef="let element">
      {{formatDate(element.updatedDate)}}
    </td>
  </ng-container>

  <!-- Amount Column -->
  <ng-container matColumnDef="totalAmount">
    <th mat-header-cell *matHeaderCellDef>Belopp</th>
    <td mat-cell *matCellDef="let element">
      {{element.totalAmount | number: '1.0-0' }} kr
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row (click)="showOrder(row)" *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator [length]="totalOrders" [pageIndex]="pageIndex" [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>