<div class="occupancy-report-wrapper">
  <h2>Beläggningsrapport</h2>

  <!-- Datepicker -->
  <div class="filters">
    <div class="date-and-export">
      <mat-form-field>
        <mat-label>Välj period</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate name="from" [formControl]="from" placeholder="Startdatum" />
          <input matEndDate name="to" [formControl]="to" placeholder="Slutdatum" />
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <button (click)="downloadReportBookings()" class="button slim secondary export"
        title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon>
      </button>
    </div>

    <!-- Indoor/outdoor toggle -->
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" value="outside" (change)="changeResourceType(group.value)">
        <mat-button-toggle value="outside">Utomhus</mat-button-toggle>
        <mat-button-toggle value="garage">Garage</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- Chart -->
  <div class="info-icon">
    <mat-icon>info</mat-icon>
    <div class="show-on-info-icon-hover">
      <h4>{{resourceType === 'garage' ? 'Garagebilar' : 'Utomhusbilar'}}</h4>
      <p><strong>Ankommande</strong> – de bilar som landar på Arlanda, dvs. som ska hämta bilen och åka ifrån
        parkeringen.</p>
      <p><strong>Avgående</strong> – de bilar som ska ut och resa, dvs. de bilar som kommer och startar sin parkering.
      </p>
    </div>
  </div>

  <div *ngIf="!loading && chartData.length > 0" class="chart">
    <stat-linechart [titleArray]="chartTitles" [chartData]="chartData" [showTooltip]="true"></stat-linechart>
  </div>

  <!-- Table -->
  <div class="table" *ngIf="occupancyReport.length > 0 && !loading">
    <table mat-table [dataSource]="occupancyReport">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let report">{{report.date | date: 'yyyy-MM-dd EEEE'}}</td>
      </ng-container>

      <!-- Empty header due to header groups -->
      <ng-container matColumnDef="empty-header">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Arriving outside -->
      <ng-container matColumnDef="arriving-outside">
        <th mat-header-cell *matHeaderCellDef class="number-data">Ankommande bilar</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[3].arrivalQty}}
        </td>
      </ng-container>

      <!-- Departing outside -->
      <ng-container matColumnDef="departing-outside">
        <th mat-header-cell *matHeaderCellDef class="number-data">Avresande bilar</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[3].departureQty}}
        </td>
      </ng-container>

      <!-- Filled spots outside -->
      <ng-container matColumnDef="filled-outside">
        <th mat-header-cell *matHeaderCellDef class="number-data">Fyllda platser</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[3].occupiedQty}}
        </td>
      </ng-container>

      <!-- Header group for outside parking -->
      <ng-container matColumnDef="outside">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Utomhus</th>
      </ng-container>

      <!-- Arriving garage -->
      <ng-container matColumnDef="arriving-garage">
        <th mat-header-cell *matHeaderCellDef class="number-data">Ankommande bilar</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[1].arrivalQty}}
        </td>
      </ng-container>

      <!-- Departing garage -->
      <ng-container matColumnDef="departing-garage">
        <th mat-header-cell *matHeaderCellDef class="number-data">Avresande bilar</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[1].departureQty}}
        </td>
      </ng-container>

      <!-- Filled spots in garage -->
      <ng-container matColumnDef="filled-garage">
        <th mat-header-cell *matHeaderCellDef class="number-data">Fyllda platser</th>
        <td mat-cell *matCellDef="let report" class="number-data">
          {{report.allocations[1].occupiedQty}}
        </td>
      </ng-container>

      <!-- Header group for garage parking -->
      <ng-container matColumnDef="garage">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Garage</th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['empty-header', 'outside', 'garage']"></tr>
      <tr mat-header-row
        *matHeaderRowDef="['date', 'arriving-outside', 'departing-outside', 'filled-outside', 'arriving-garage', 'departing-garage', 'filled-garage']">
      </tr>
      <tr mat-row
        *matRowDef="let rowData; columns: ['date', 'arriving-outside', 'departing-outside', 'filled-outside', 'arriving-garage', 'departing-garage', 'filled-garage']">
      </tr>
    </table>
  </div>

  <!-- Spinner -->
  <div class="spinner">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>

  <!-- No data warning -->
  <div *ngIf=" !occupancyReport.length&& !loading" class="{{!errorMessage ? 'empty-content' : 'error-content'}}">
    <p>{{errorMessage ? 'Någonting gick fel, kunde inte hämta data för den valda perioden.' :
      'Det finns inga data för den valda perioden.'}}</p>
  </div>
</div>