<div class="container">
  <h2>Prislistor</h2>

  <div class="top-wrapper" >
    <!-- Warning features without a price -->
    <div class="warning-wrapper" *ngIf="unpricedItems">
      <div class="warning-header">
        <mat-icon>report_problem</mat-icon>
        <strong>Det finns prislistor som är nära slutdatum eller artiklar som saknar pris!</strong>
      </div>
      
      <ul>
        <li *ngFor="let feature of missingFeaturePrices">{{feature}}</li>
        <li *ngFor="let resource of missingResourcePrices">{{resource}}</li>
      </ul>
    </div>

    <!-- Create + copy buttons -->
    <div *ngIf="isAdmin" class="buttons-wrapper">
      <button class="button slim with-icon" (click)="openCreatePricelistModal()"><mat-icon>add</mat-icon> Skapa
        prislista</button>
      <button class="button slim with-icon" (click)="openCopyPricelistModal()">
        <mat-icon class="content-copy-icon">content_copy</mat-icon> Kopiera prislista
      </button>
    </div>
  </div>


  <mat-table *ngIf="dataSource$" [dataSource]="dataSource$">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let pricelist"> {{ pricelist.priceListId }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Namn">
      <mat-header-cell *matHeaderCellDef> Namn </mat-header-cell>
      <mat-cell *matCellDef="let pricelist"> {{ pricelist.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Typ">
      <mat-header-cell *matHeaderCellDef> Typ </mat-header-cell>
      <mat-cell *matCellDef="let pricelist"> {{ pricelist.resource?.label }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Datum">
      <mat-header-cell *matHeaderCellDef> Aktiv </mat-header-cell>
      <mat-cell *matCellDef="let pricelist"> {{formatDate(pricelist.validFrom)}} - {{formatDate(pricelist.validTo)}} <span
          *ngIf="!pricelist.validTo">Tillsvidare</span> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" class="clickable"
      (click)="navigateToPriceListById(row.priceListId)"></mat-row>
  </mat-table>
</div>