<h2 class="no-margin-bottom">Alla bokningar</h2>

<div class="filter-wrapper">
    <!-- Search and filters -->
    <form class="filters" *ngIf="searchAndFilterBookingsForm" [formGroup]="searchAndFilterBookingsForm">
        <div>
            <input class="search-input" type="search" placeholder="Sök på reg.nr/bokningsnr./flightnr."
                formControlName="searchInput" (input)="search()" data-test="search-all-bookings" spellcheck="false"
                autocomplete="off" />

            <div class="radio-wrapper">
                <!-- Location filter -->
                <mat-radio-group formControlName="location">
                    <h5 class="filters-group-label">Plats:</h5>
                    <mat-radio-button color="primary" value="all">Alla</mat-radio-button>
                    <mat-radio-button color="primary" value="PARKING_GARAGE">Garage</mat-radio-button>
                    <mat-radio-button color="primary" value="PARKING_OUTDOOR">Utomhus</mat-radio-button>
                </mat-radio-group>

                <!-- Feature filter -->
                <mat-radio-group formControlName="feature">
                    <h5 class="filters-group-label">Filter:</h5>
                    <mat-radio-button color="primary" value="all">Alla</mat-radio-button>
                    <mat-radio-button color="primary" value="GARAGE">Laddbilar</mat-radio-button>
                    <mat-radio-button color="primary" value="CAR_WASH">Tvättbilar</mat-radio-button>
                    <mat-radio-button color="primary" value="WORKSHOP">Verkstadsbilar</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="date-filter">
            <!-- Date filter -->
            <mat-form-field>
                <mat-label>Period</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate name="from" formControlName="from" placeholder="Från" />
                    <input matEndDate name="to" formControlName="to" placeholder="Till" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-radio-group formControlName="filterDateOn">
                <mat-radio-button [value]="'arrival'" color="primary">Ankomst</mat-radio-button>
                <mat-radio-button [value]="'departure'" color="primary">Avresa</mat-radio-button>
            </mat-radio-group>
        </div>
    </form>
</div>

<mat-paginator [length]="totalBookings" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>

<div class="spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="empty-content" *ngIf="bookings.length <= 0">
    <p>Det gick inte att hitta någon bokning
        <span *ngIf="searchAndFilterBookingsForm && searchAndFilterBookingsForm.controls['searchInput'].value">
            som matchar "{{searchAndFilterBookingsForm.controls['searchInput'].value}}"
        </span>.
    </p>
</div>

<div class="error-content" *ngIf="errorMessage">
    <p>Någonting gick fel vid hämtning av bokningar. Ladda om sidan för att göra ett nytt försök.</p>
    <p>Om problemet kvarstår, kontakta Rely IT för support.</p>
</div>

<!-- Table -->
<table *ngIf="bookings && bookings.length > 0" mat-table [dataSource]="bookings" matSort matSortStart="desc"
    matSortDisableClear (matSortChange)="sortColumns($event)">
    <!-- Booking number Column -->
    <ng-container matColumnDef="bookingNumber">
        <th mat-header-cell *matHeaderCellDef>Bokningsnr.</th>
        <td mat-cell *matCellDef="let element">
            {{ element.bookingId }}
        </td>
    </ng-container>

    <!-- Registration number Column -->
    <ng-container matColumnDef="registrationNumber">
        <th mat-header-cell *matHeaderCellDef>Reg.nr</th>
        <td mat-cell *matCellDef="let element">
            {{element.registrationNumber}}
        </td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Plats</th>
        <td mat-cell *matCellDef="let element">
            {{element.resourceLabel}}
        </td>
    </ng-container>

    <!-- Features Column -->
    <ng-container matColumnDef="features">
        <th mat-header-cell *matHeaderCellDef>Tjänster</th>
        <td mat-cell *matCellDef="let element">
            <span *ngFor="let value of element.features">{{value.featureName + ' '}}</span>
            <span *ngIf="element.features.length <= 0 ">-</span>
        </td>
    </ng-container>

    <!-- Flight Column -->
    <ng-container matColumnDef="flightNumber">
        <th mat-header-cell *matHeaderCellDef>Flight</th>
        <td mat-cell *matCellDef="let element">
            {{getFlightNumber(element)}}
        </td>
    </ng-container>

    <!-- Departure date Column -->
    <ng-container matColumnDef="departureDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sortera på datum">Avresa</th>
        <td mat-cell *matCellDef="let element">
            {{formatDate(element.departureDate)}}
        </td>
    </ng-container>

    <!-- Arrival date Column -->
    <ng-container matColumnDef="arrivalDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Ankomst</th>
        <td mat-cell *matCellDef="let element">
            {{formatDate(element.arrivalDate)}}
        </td>
    </ng-container>

    <!-- Passengers Column -->
    <ng-container matColumnDef="passengers">
        <th mat-header-cell *matHeaderCellDef>Resenärer</th>
        <td mat-cell *matCellDef="let element">
            {{element.qtyPersons}}
        </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef>Kommentar</th>
        <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.comment" matTooltip="{{ element.comment }}">chat_bubble</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="showBooking(row)" *matRowDef="let row; columns: displayedColumns"></tr>
</table>


<mat-paginator [length]="totalBookings" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>