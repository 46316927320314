import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderDTO, PaymentDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { Refund } from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-klarna-refund',
  templateUrl: './klarna-refund.component.html',
  styleUrls: ['./klarna-refund.component.scss']
})
export class KlarnaRefundComponent {
  refundControl: FormControl = new FormControl(undefined, [Validators.max(this.data.maxRefund), Validators.min(0)]);
  refundComment = new FormControl('', Validators.required);
  private onDestroy$ = new Subject<void>();

  constructor(private dialog: MatDialogRef<KlarnaRefundComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderDTO, refund: PaymentDTO, maxRefund: number, orderItemId: number },
    private store: Store) { }

  closeDialog() {
    this.dialog.close();
  }

  handleRefund() {
    if(this.refundControl.value <= this.data.maxRefund) {
      const payment: PaymentDTO = {
        paymentMethod: this.data.refund.paymentMethod,
        transactionType: 'REFUND',
        amount: this.refundControl.value,
        comment: this.refundComment.value!
      }

      this.store.dispatch(new Refund(this.data.order.orderId!, this.data.orderItemId, this.data.refund.paymentId!, payment)).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.dialog.close({success: true});
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
