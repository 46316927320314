<h3>Lindskrog Parkering, Arlanda</h3>

<!-- Org information -->
<div class="info-set contact-info">
  <p>Org. Nr: 556538-3691</p>
  <p>Tel: 08-59361285</p>
</div>

<h4>Summering</h4>

<div class="order-info">
  <p>Order nr: {{order?.orderId}}</p>
  <p>Order skapad: {{formatDate(order?.createdDate)}} {{formatTime(order?.createdDate)}}</p>
</div>

<div class="order-items">
  <!-- Items in order -->
  <div *ngFor="let item of order?.orderItems">

    <!-- Parking -->
    <div *ngIf="item.orderItemType === ItemType.RESOURCE">
      <p class="info-set"><span>Bokning</span><strong>{{item.amount | number}} kr</strong></p>
      <div class="booking-info">
        <p class="info-set"><span> Bokningsnr.:</span><span> {{item.booking!.qr}}</span></p>
        <p class="info-set"><span>Starttid:</span><span> {{item.booking?.departureDate | date: 'yyyy-MM-dd HH:mm'}}</span>
        </p>
        <p class="info-set"><span>Sluttid:</span>
          <span>  {{item.booking?.arrivalDate | date: 'yyyy-MM-dd HH:mm'}}</span>
        </p>
        <p class="info-set"><span>Registreringsnr.:</span><span> {{item.booking!.registrationNumber}}</span></p>
        <p class="info-set"><span>Plats:</span><span> {{item.booking!.resource?.label}}</span></p>
        <p class="info-set"><span>Resenärer:</span><span> {{item.booking!.qtyPersons}}</span></p>
        <p class="info-set" *ngIf="item.booking?.childSafetySeat"><span>Bilbarnstol:</span><span>Ja</span></p>
        <p class="info-set" *ngIf="item.booking?.handLuggageOnly"><span>Endast handbagage:</span><span>Ja</span></p>
        <p class="info-set" *ngIf="!item.booking?.handLuggageOnly"><span>Incheckat bagage:</span><span>Ja</span></p>
        <i class="info-set" *ngIf="item.discount"><span>Rabatt:</span><span>-{{item.discount | number}} kr</span></i>
      </div>
    </div>

    <!-- Feature -->
    <div *ngIf="item.orderItemType === ItemType.FEATURE" class="feature-info">
      <p class="info-set">
        <span>{{item.feature?.name}}</span>
        <strong>{{item.amount | number}} kr</strong>
      </p>
      <i class="info-set discount-feature" *ngIf="item.discount"><span>Rabatt:</span><span>-{{item.discount | number}}
          kr</span></i>
    </div>

    <!-- Prepaid ticket -->
    <div *ngIf="item.orderItemType === ItemType.PREPAIDTICKET">
      <p class="info-set">
        <span>Abonnemang</span>
        <strong>{{item.prepaidTicket?.amount | number}} kr</strong>
      </p>
    </div>
  </div>
</div>

<!-- Pickup info -->
<div *ngIf="!loading" class="pickup-info">
  <p *ngFor="let info of pickupInfo">{{info}}</p>
</div>