<button mat-button *ngIf="reqSent" (click)="toLogin()">Tillbaka till inloggning</button>

<h2>Återställ lösenord</h2>

<div *ngIf="!reqSent" class="content">
  <p>Om du har glömt ditt lösenord kan du få ett mejl med en länk för att återställa det. 
    Länken går ut efter 30 minuter för att skydda ditt konto. 
    Om du kommer på ditt lösenord i efterhand kommer du fortfarande in med det - även om du har begärt återställning.
  </p>

  <form *ngIf="email" (ngSubmit)="sendResetRequest()">
    <mat-form-field>
      <input matInput [formControl]="email" name="email" placeholder="E-post" spellcheck="false" autocomplete="off">
    </mat-form-field>

    <button class="button" type="submit">Skicka</button>
  </form>
</div>

<div *ngIf="reqSent" class="content">
  
  <p>
    Vi har skickat ett mejl med en återställningslänk till adressen du angivit. 
    Länken går ut efter 30 minuter för att skydda ditt konto.
  </p>
</div>