<nav class="page-nav">
  <mat-toolbar color="primary">
    <button [matMenuTriggerFor]="bookingMenu" #bookingMenuTrigger="matMenuTrigger"
      class="{{bookingMenuTrigger.menuOpen ? 'sub-menu-open' : rlBooking.isActive || rlAll.isActive ? 'active-link' : ''}}">
      Bokningar
    </button>
    <mat-menu #bookingMenu="matMenu" class="sub-menu">
      <button [routerLink]="['/hem']" routerLinkActive="active-link"
        #rlBooking="routerLinkActive">Hämtlista/Bokningar</button>
      <button [routerLink]="['/alla-bokningar']" routerLinkActive="active-link" #rlAll="routerLinkActive">
        Alla bokningar
      </button>
    </mat-menu>

    <button [matMenuTriggerFor]="orderMenu" #orderMenuTrigger="matMenuTrigger"
      class="{{orderMenuTrigger.menuOpen ? 'sub-menu-open' : rlOrder.isActive ? 'active-link' : ''}}">
      Ordrar
    </button>
    <mat-menu #orderMenu="matMenu" class="sub-menu">
      <button [routerLink]="['/ordrar']" routerLinkActive="active-link" #rlOrder="routerLinkActive">Alla ordrar</button>
      <!-- <button routerLinkActive="active-link">Fakturor</button> -->
    </mat-menu>
    
    <button [routerLink]="['/garage']" routerLinkActive="active-link">Garage</button>
    <!-- <button>Tvätt</button> -->
    <button [routerLink]="['/prislistor']" routerLinkActive="active-link">Prislistor</button>
    <button [routerLink]="['/abonnemang']" routerLinkActive="active-link">Abonnemang</button>
    <button *ngIf="isAdmin" [routerLink]="['/schema']" routerLinkActive="active-link">Schema</button>
    <button [routerLink]="['/avidentifiering']">Avidentifiering</button>
    <span class="toolbar-spacer"></span>
    <button [routerLink]="['/relyit']" routerLinkActive="active-link"
      *ngIf="!isProd && (description == 'utvecklingsmiljö' || description == 'local')">Rely IT test</button>
  </mat-toolbar>
  
  <app-navbar-admin *ngIf="isAdmin"></app-navbar-admin>
</nav>