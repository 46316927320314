import { Injectable } from '@angular/core';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import {
  BookingDTO,
  DefaultService,
  FlightBookingDTO,
  HourBookingDTO,
  PageBookingDTO,
  PageGarageBookingDTO,
} from 'parking-sdk';
import * as Actions from './booking.actions';
import { forkJoin, tap } from 'rxjs';

export interface BookingsStateModel {
  currentBooking?: BookingDTO;
  currentBookings: BookingDTO[];
  allBookings?: PageBookingDTO;
  currentArrivalBookings: FlightBookingDTO[];
  currentDepartureBookings: HourBookingDTO[];
  currentGarageBookings?: PageGarageBookingDTO;
  currentEditBooking?: BookingDTO;
  searchResult?: BookingDTO[];
  searchResultUpcoming?: BookingDTO[];
  searchResultHistory?: BookingDTO[];
  upcomingTimeRange?: { fromHour: Date; toHour: Date };
}

@State<BookingsStateModel>({
  name: 'bookings',
  defaults: {
    currentBooking: undefined,
    currentBookings: [],
    allBookings: undefined,
    currentArrivalBookings: [],
    currentDepartureBookings: [],
    currentGarageBookings: undefined,
    currentEditBooking: undefined,
    searchResult: undefined,
    searchResultUpcoming: undefined,
    searchResultHistory: undefined,
    upcomingTimeRange: undefined,
  },
})
@Injectable()
export class BookingsState {
  constructor(private defaultService: DefaultService) {}

  @Selector()
  static currentBookings(state: BookingsStateModel) {
    return state.currentBookings;
  }

  @Selector()
  static currentBooking(state: BookingsStateModel) {
    return state.currentBooking;
  }

  @Selector()
  static currentEditBooking(state: BookingsStateModel) {
    return state.currentEditBooking;
  }

  @Selector()
  static currentArrivalBookings(state: BookingsStateModel) {
    return state.currentArrivalBookings;
  }

  @Selector()
  static currentDepartureBookings(state: BookingsStateModel) {
    return state.currentDepartureBookings;
  }

  @Selector()
  static currentGarageBookings(state: BookingsStateModel) {
    return state.currentGarageBookings;
  }

  @Selector()
  static allBookings(state: BookingsStateModel) {
    return state.allBookings;
  }

  @Selector()
  static searchResult(state: BookingsStateModel) {
    return state.searchResult;
  }

  @Selector()
  static searchResultUpcoming(state: BookingsStateModel) {
    return state.searchResultUpcoming;
  }

  @Selector()
  static searchResultHistory(state: BookingsStateModel) {
    return state.searchResultHistory;
  }

  @Selector()
  static upcomingTimeRange(state: BookingsStateModel) {
    return state.upcomingTimeRange;
  }

  @Action(Actions.GetBooking)
  getBooking(
    context: StateContext<BookingsStateModel>,
    action: Actions.GetBooking
  ) {
    return this.defaultService.getBookingAdmin(action.id).subscribe({
      next: (data) => {
        context.patchState({ currentBooking: data });
      },
      error: (error) => {
        console.error('Error fetching booking: ', error);
      },
    });
  }

  @Action(Actions.GetEditBooking)
  getEditBooking(
    context: StateContext<BookingsStateModel>,
    action: Actions.GetEditBooking
  ) {
    return (
      action.id &&
      this.defaultService.getBookingAdmin(action.id).pipe(
        tap({
          next: (data) => {
            context.patchState({ currentEditBooking: data });
          },
          error: (error) => {
            console.error('Error fetching edit booking: ', error);
          },
        })
      )
    );
  }

  @Action(Actions.DoneEditBooking)
  doneEditBooking(context: StateContext<BookingsStateModel>) {
    return context.patchState({ currentEditBooking: undefined });
  }

  @Action(Actions.GetCurrentBookings)
  getCurrentBookings(context: StateContext<BookingsStateModel>) {
    return forkJoin([
      this.defaultService.findCurrentFlights('ARRIVAL'),
      this.defaultService.findCurrentDepartureBookings(),
    ]).pipe(
      tap(([currentArrivals, currentDepartures]) => {
        // Update state
        context.patchState({ currentDepartureBookings: currentDepartures });
        context.patchState({ currentArrivalBookings: currentArrivals });
        context.patchState({
          currentBookings: [
            ...(currentArrivals || []),
            ...(currentDepartures || []),
          ],
        });
      })
    );
  }

  @Action(Actions.GetGarageBookings)
  getGarageBookings(
    context: StateContext<BookingsStateModel>,
    action: Actions.GetGarageBookings
  ) {
    const today = new Date();
    const nextWeek = new Date(new Date().setDate(today.getDate() + 8));
    nextWeek.setHours(0, 0, 0);

    return this.defaultService
      .getGarageBookingsAdmin(
        action.pageIndex,
        action.pageSize,
        action.searchTerm || undefined
      )
      .pipe(
        tap((v) => {
          context.patchState({ currentGarageBookings: v });
        })
      );
  }

  @Action(Actions.GetAllBookings)
  getAllBookings(
    context: StateContext<BookingsStateModel>,
    action: Actions.GetAllBookings
  ) {
    const column = action.sortColumn ? action.sortColumn : 'arrivalDate';

    const departureFromDate =
      action.dateFilterType === 'departure' && action.startDate
        ? action.startDate
        : undefined;

    const departureToDate =
      action.dateFilterType === 'departure' && action.endDate
        ? action.endDate
        : undefined;

    const arrivalFromDate =
      action.dateFilterType === 'arrival' && action.startDate
        ? action.startDate
        : undefined;

    const arrivalToDate =
      action.dateFilterType === 'arrival' && action.endDate
        ? action.endDate
        : undefined;

    return this.defaultService
      .findBookingsAdmin(
        action.pageIndex,
        action.pageSize,
        `${column},${action.sortOrder}`,
        action.searchTerm || undefined,
        departureFromDate,
        departureToDate,
        arrivalFromDate,
        arrivalToDate,
        action.resourceName,
        action.mainFeatureType,
      )
      .pipe(
        tap((v) => {
          context.patchState({ allBookings: v });
        })
      );
  }

  @Action(Actions.FindBookings)
  findBookings(
    context: StateContext<BookingsStateModel>,
    action: Actions.FindBookings
  ) {
    const today = new Date(new Date().setHours(0, 0, 0));
    const yesterday = new Date(new Date().setHours(0, -1, 0));

    return forkJoin([
      this.defaultService.getBookingsAdmin(
        undefined,
        undefined,
        'departureDate,asc',
        action.searchTerm || undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        today,
        undefined
      ),
      this.defaultService.getBookingsAdmin(
        undefined,
        undefined,
        'arrivalDate,desc',
        action.searchTerm || undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        yesterday
      ),
    ]).pipe(
      tap(([upcomingBookings, historyBookings]) => {
        context.patchState({ searchResultUpcoming: upcomingBookings.content });
        context.patchState({ searchResultHistory: historyBookings.content });
      })
    );
  }

  @Action(Actions.FindBookingsByDirection)
  findBookingsByDirection(
    context: StateContext<BookingsStateModel>,
    action: Actions.FindBookingsByDirection
  ) {
    const fromHour = context.getState().upcomingTimeRange?.fromHour;
    const toHour = context.getState().upcomingTimeRange?.toHour;

    if (action.direction == 'arrival') {
      return this.defaultService
        .getBookingsAdmin(
          undefined,
          undefined,
          'arrivalDate,asc',
          action.searchTerm || undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          fromHour,
          toHour
        )
        .pipe(
          tap((value) => {
            context.patchState({ searchResult: value.content });
          })
        );
    } else {
      return this.defaultService
        .getBookingsAdmin(
          undefined,
          undefined,
          'departureDate,asc',
          action.searchTerm || undefined,
          undefined,
          undefined,
          fromHour,
          toHour
        )
        .pipe(
          tap((value) => {
            context.patchState({ searchResult: value.content });
          })
        );
    }
  }

  @Action(Actions.GetUpcomingTimeRange)
  getUpcomingTimeRange(
    context: StateContext<BookingsStateModel>,
    action: Actions.GetUpcomingTimeRange
  ) {
    const now = new Date();

    return forkJoin([
      this.defaultService.getSetting('CURRENT_BOOKING_FROM_MINUTES'),
      this.defaultService.getSetting('CURRENT_BOOKING_TO_MINUTES'),
    ]).pipe(
      tap(([currentFromMinutesOffset, currentToMinutesOffset]) => {
        let fromHoursOffset = Math.trunc(
          Number(currentFromMinutesOffset.value) / 60
        );
        const fromMinutesOffset =
          now.getMinutes() -
          Math.abs(Number(currentFromMinutesOffset.value) % 60);
        let toHoursOffset = Math.trunc(
          Number(currentToMinutesOffset.value) / 60
        );
        let toMinutesOffset =
          now.getMinutes() + (Number(currentToMinutesOffset.value) % 60);

        if (fromMinutesOffset < 0) {
          fromHoursOffset--;
        }

        if (toMinutesOffset > 60) {
          toHoursOffset++;
          toMinutesOffset -= 60;
        }

        const minRoundDown =
          (Math.round((Math.abs(fromMinutesOffset) - 7.5) / 15) * 15) % 60; // nearest 15 min down, 00, 15, 30, 45
        const minRoundUp = (Math.round((toMinutesOffset + 7.4) / 15) * 15) % 60; // nearest 15 min up, 00, 15, 30, 45
        if (toMinutesOffset > 45) toHoursOffset++;

        const fromHour = new Date(
          new Date(now).setHours(
            now.getHours() + fromHoursOffset,
            minRoundDown,
            0
          )
        );
        const toHour = new Date(
          new Date(now).setHours(now.getHours() + toHoursOffset, minRoundUp, 0)
        );

        // Update state
        context.patchState({ upcomingTimeRange: { fromHour, toHour } });
      })
    );
  }

  @Action(Actions.UpdateQtyPersonsCheckedOff)
  updateQtyPersonsCheckedOff(
    context: StateContext<BookingsStateModel>,
    action: Actions.UpdateQtyPersonsCheckedOff
  ) {
    const { direction, bookingId, qtyPersonsCheckedOff } = action;
    if (direction === 'arrival') {
      return this.defaultService
        .updateQtyPersonsPickedUp(bookingId, {
          bookingId,
          qtyPersonsPickedUp: qtyPersonsCheckedOff,
        })
        .pipe(
          tap((v) => {
            context.dispatch(new Actions.GetCurrentBookings());
          })
        );
    } else if (direction === 'departure') {
      return this.defaultService
        .updateQtyPersonsDroppedOff(bookingId, {
          bookingId,
          qtyPersonsPickedUp: qtyPersonsCheckedOff,
        })
        .pipe(
          tap((v) => {
            context.dispatch(new Actions.GetCurrentBookings());
          })
        );
    }
    return;
  }

  @Action(Actions.CancelBooking)
  cancelBooking(
    context: StateContext<BookingsStateModel>,
    action: Actions.CancelBooking
  ) {
    const { bookingId } = action;
    return this.defaultService.cancelBooking(bookingId).pipe(
      tap((v) => {
        context.dispatch(new Actions.GetCurrentBookings());
      })
    );
  }

  @Action(Actions.ResetBookingState)
  resetState(context: StateContext<BookingsStateModel>) {
    context.setState({
      currentBooking: undefined,
      currentBookings: [],
      allBookings: undefined,
      currentArrivalBookings: [],
      currentDepartureBookings: [],
      currentGarageBookings: undefined,
      currentEditBooking: undefined,
      searchResult: undefined,
      searchResultUpcoming: undefined,
      searchResultHistory: undefined,
      upcomingTimeRange: undefined,
    });
  }
}
