import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  standalone: true,
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {
  public qrUrl: string = '';

  constructor(
    private route: ActivatedRoute) {
    let guid = this.route.snapshot.paramMap.get('guid');
    this.qrUrl = `${environment.backendServerUrl}/public/bookings/qr?guid=${guid}`;
  }

  ngOnInit(): void {
  }

}
