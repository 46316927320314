import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExportService } from 'src/app/core/services/export.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-daily-payment-and-article',
  templateUrl: './daily-payment-and-article.component.html',
  styleUrls: ['./daily-payment-and-article.component.scss'],
})
export class DailyPaymentAndArticleComponent {
  from = new FormControl<Date | undefined | string>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  to = new FormControl<Date | undefined | string>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );

  constructor(private exportService: ExportService) {}

  downloadReport() {
    if (this.from.value && this.to?.value)
      this.exportService.getPaymentReport(
        dayjs(this.from.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.to.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate()
      );
  }
}
