import { NgModule } from "@angular/core";
import { SmsSettingsComponent } from "./sms-settings.component";
import { SharedModule } from "src/app/shared/shared.module";


@NgModule({
    declarations: [ SmsSettingsComponent ],
    imports: [ SharedModule ],
    exports: [ SmsSettingsComponent ]
})
export class SmsSettingsModule{}