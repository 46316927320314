<h2 class="title">Administrera antal parkeringsplatser</h2>
<div class="parking-lot-header">
    <button class="button slim with-icon" (click)="createAvailability()"><mat-icon>add</mat-icon> Ny period</button>
</div>

<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef>Startdatum</th>
        <td mat-cell *matCellDef="let card">{{card.validFrom | date:'yyy-MM-dd'}}</td>
    </ng-container>
    <ng-container matColumnDef="validTo">
        <th mat-header-cell *matHeaderCellDef>Slutdatum</th>
        <td mat-cell *matCellDef="let card">{{card.validTo ? (card.validTo | date:'yyy-MM-dd') : 'Tillsvidare'}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Typ av parkering</th>
        <td mat-cell *matCellDef="let card">{{card.resource.label}}</td>
    </ng-container>
    <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef>Antal platser</th>
        <td mat-cell *matCellDef="let card">{{card.qty}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let rowData; columns:displayedColumns;" (click)="editAvailability(rowData)"></tr>
</table>
