<div mat-dialog-content class="print-dialog-wrapper">
  <h4>Skriv ut {{data?.currentTopic === 'arrival' ? 'hämlista': 'bokningar'}}</h4>

  <form [formGroup]="timeRangeForm" class="print-range" (change)="checkDateRange()">
    <div class="form-fieldset parking">
      <mat-form-field>
        <mat-label>Välj dag</mat-label>
        <input matInput type="date" placeholder="Datum" name="parkingDate" formControlName="date" autocomplete="off"
          [matDatepicker]="picker" />
          <mat-hint>ÅÅÅÅ-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-fieldset">
      <mat-form-field>
        <mat-label>Starttid</mat-label>
        <input matInput type="time" placeholder="HH:mm" name="parkingStartTime" formControlName="startTime"
          autocomplete="off" />
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Sluttid{{fromLaterThanTo ? '&#42;' : ''}}</mat-label>
        <input matInput type="time" placeholder="HH:mm" name="parkingEndTime" formControlName="endTime"
          autocomplete="off" />
        <mat-hint *ngIf="fromLaterThanTo"> &#42;{{to && dateService.formatDate(to)}}</mat-hint>
        <mat-icon matSuffix>access_time</mat-icon>
      </mat-form-field>
    </div>
  </form>

  <div mat-dialog-actions class="print-buttons">
    <button mat-flat-button (click)="onCancelClick()">Avbryt</button>
    <button mat-raised-button (click)="reloadIframeAndPrint()" color="primary">Förhandsgranska</button>
  </div>

  <div class="spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  
  <iframe #pickupListFrame frameBorder="0" width="750" height="500" id="pickupListFrame" name="pickupListFrame"
    (load)="onFrameLoad()">
  </iframe>
</div>