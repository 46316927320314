<div class="advance-payment-report-wrapper">
  <h2>Förskottsbetalningar</h2>
  <p>Betalningar där tjänsten ännu inte blivit nyttjad. Datan visar en ögonblicksbild från och med nu och framåt.</p>


  <div class="exports">
    <button (click)="downloadReport()" class="button slim secondary"
      title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon>
    </button>

    <button (click)="downloadReportDetails()" class="button slim secondary"
      title="Exportera detaljerad rapport till CSV">Exportera detaljerad rapport<mat-icon>save_alt</mat-icon>
    </button>
  </div>

  <!-- Table -->
  <table mat-table [dataSource]="advancePaymentReport" *ngIf="advancePaymentReport"
    class="advance-payment-report-table">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Betalningstyp</th>
      <td mat-cell *matCellDef>Förskott</td>
    </ng-container>

    <ng-container matColumnDef="brutto">
      <th mat-header-cell *matHeaderCellDef class="number-data">Brutto</th>
      <td mat-cell *matCellDef="let payment" class="number-data">{{payment.brutto | number : "1.0-0"}} kr</td>
    </ng-container>

    <ng-container matColumnDef="netto">
      <th mat-header-cell *matHeaderCellDef class="number-data">Netto</th>
      <td mat-cell *matCellDef="let payment" class="number-data">{{payment.netto | number : "1.0-0"}} kr</td>
    </ng-container>

    <ng-container matColumnDef="vat">
      <th mat-header-cell *matHeaderCellDef class="number-data">Varav moms (25%)</th>
      <td mat-cell *matCellDef="let payment" class="number-data">{{payment.vat | number : "1.0-0"}} kr</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['type', 'brutto', 'netto', 'vat']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['type', 'brutto', 'netto', 'vat']"></tr>
  </table>

  <!-- Empty -->
  <div class="{{!errorMessage ? 'empty-content' : 'error-content'}}" *ngIf="(advancePaymentReport?.length || 0) <= 0">
    <p>{{errorMessage ? 'Någonting gick fel, kunde inte hämta data.' :
      'Just nu finns det inga data för förskottsbetalningar.'}}</p>
  </div>
</div>