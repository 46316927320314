import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import dayjs from 'dayjs';
import {
  PrepaidTicketDTO,
  DefaultService,
  ResourceDTO,
  PrepaidTicketCarDTO,
} from 'parking-sdk';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Store } from '@ngxs/store';
import {
  DeletePrepaidTicket,
  UpdatePrepaidTicket,
} from 'src/app/core/states/prepaid/prepaid.actions';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-prepaid-create',
  templateUrl: './prepaid-create.component.html',
  styleUrls: ['./prepaid-create.component.scss'],
})
export class PrepaidCreateComponent implements OnInit, OnDestroy {
  prepaidTicket?: PrepaidTicketDTO;
  resourceOptions: ResourceDTO[] = [];
  editMode = false;

  /* Form */
  prepaidForm?: FormGroup;
  invalidFormMessage?: string = undefined;

  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PrepaidCreateComponent>,
    private dialog: MatDialog,
    private defaultService: DefaultService,
    private store: Store,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { create: boolean; ticket: PrepaidTicketDTO }
  ) {}

  ngOnInit(): void {
    this.defaultService.getPrepaidTicketResources().pipe(takeUntil(this.onDestroy$)).subscribe((resources) => {
      this.resourceOptions = resources;

      this.prepaidTicket = !this.data.create ? this.data.ticket : undefined;
      this.editMode = !this.data.create;

      this.prepaidForm = this.formBuilder.group({
        name: new FormControl<string | undefined>(
          !this.data.create ? this.data.ticket.name : undefined,
          [Validators.required]
        ),
        maxCars: new FormControl<number>(
          !this.data.create ? this.data.ticket.maxPark || 0 : 0,
          [Validators.required]
        ),
        validFrom: new FormControl<Date | undefined>(
          !this.data.create ? this.data.ticket.validFrom : undefined,
          [Validators.required]
        ),
        validTo: new FormControl<Date | undefined>(
          !this.data.create ? this.data.ticket.validTo : undefined,
          [Validators.required]
        ),
        amount: new FormControl<number | undefined>(
          !this.data.create ? this.data.ticket.amount : undefined,
          [Validators.required]
        ),
        comment: new FormControl<string | undefined>(
          !this.data.create ? this.data.ticket.comment : undefined,
          [Validators.required]
        ),
        resource: new FormControl<ResourceDTO>(
          !this.data.create
            ? this.data.ticket.resource || this.resourceOptions[0]
            : this.resourceOptions[0],
          [Validators.required]
        ),
        active: new FormControl<boolean>(
          !this.data.create ? this.data.ticket.active || false : false,
          [Validators.required]
        ),
        car: new FormControl<string | undefined>(undefined),
      });

      if (!this.data.create) this.prepaidForm.controls['amount'].disable();
      this.updatePrepaidTicket();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  onRemoveClick() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill ta bort abonnemang "${this.prepaidTicket?.name}"?`,
        message: [`Det kommer inte gå att återställa abonnemanget.`],
      },
    });

    confDialog.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      if (result && this.prepaidTicket?.prepaidTicketId) {
        this.store.dispatch(
          new DeletePrepaidTicket(this.prepaidTicket.prepaidTicketId)
        );
        this.dialogRef.close();
      }
    });
  }

  updatePrepaidTicket(): void {
    let cars = this.prepaidTicket?.cars || [];

    /* Add car if input has a value */
    if (this.prepaidForm?.controls['car'].value) {
      cars = [
        ...cars,
        {
          registrationNumber:
            this.prepaidForm?.controls['car'].value.toUpperCase(),
        },
      ];
    }

    this.prepaidTicket = {
      ...this.prepaidTicket,
      name: this.prepaidForm?.controls['name'].value || undefined,
      maxPark: this.prepaidForm?.controls['maxCars'].value || undefined,
      validFrom: this.prepaidForm?.controls['validFrom'].value || undefined,
      validTo: this.prepaidForm?.controls['validTo'].value || undefined,
      amount: this.prepaidForm?.controls['amount'].value || undefined,
      comment: this.prepaidForm?.controls['comment'].value || undefined,
      resource: this.prepaidForm?.controls['resource'].value || undefined,
      active: this.prepaidForm?.controls['active'].value || false,
      cars: cars,
    };
  }

  removeCar(car?: PrepaidTicketCarDTO) {
    if (car) {
      const confDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Är du säker på att du vill ta bort registreringsnumret "${car.registrationNumber}"?`,
          message: [`Det kommer inte gå att återställa registreringsnumret.`],
        },
      });

      confDialog.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
        if (result && this.prepaidTicket?.prepaidTicketId) {
          if (this.prepaidTicket?.cars?.length)
            this.prepaidTicket.cars = this.prepaidTicket.cars.filter(
              (registeredCar) =>
                registeredCar.registrationNumber != car.registrationNumber
            );
        }
      });
    }
  }

  clearCarInput() {
    if (this.prepaidForm) this.prepaidForm.controls['car'].setValue(undefined);
  }

  onCreateClick() {
    if (this.prepaidForm?.valid) {
      if (this.data.create) {
        this.dialogRef.close(this.prepaidTicket);
      } else if (this.prepaidTicket?.prepaidTicketId) {
        this.store.dispatch(
          new UpdatePrepaidTicket(
            this.prepaidTicket.prepaidTicketId,
            this.prepaidTicket
          )
        );
        this.dialogRef.close(true);
      }
    } else {
      this.invalidFormMessage = 'Ett eller flera fält är ogiltiga';
      console.error('All fields not input correctly');
    }
  }
}
