<h2>Betalningsbektäftelse på order {{orderId}}</h2>
<div *ngIf="klarnaSnippet" [innerHTML]="klarnaSnippet | keepHtml" runScripts>
</div>

<div *ngIf="order">
    <div *ngFor="let orderItem of order.orderItems" class="order-item">
        <div *ngIf="orderItem.orderItemType === 'RESOURCE' && orderItem.booking">
            <div>BookingId: {{orderItem.booking.bookingId}}</div>
            <div>Registreringsnummer: {{orderItem.booking.registrationNumber}}</div>
            <div>Datum: {{orderItem.booking.departureDate | date }} - {{orderItem.booking.arrivalDate | date }}</div>
            <div>
                QR: {{orderItem.booking.qr}}
                <img [src]="'data:image/png;base64,' + orderItem.booking.qrImage">
            </div>
        </div>
        <div *ngIf="orderItem.orderItemType === 'FEATURE' && orderItem.feature">
            <div>FeatureId: {{orderItem.feature.featureId}}</div>
            <div>
                Name: {{orderItem.feature.name}}
            </div>
        </div>
    </div>
</div>