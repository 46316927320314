import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  DefaultService,
  OrderDTO,
  PaymentDTO,
  PaymentInvoiceDTO,
} from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { DateService } from 'src/app/core/services/date.service';
import { OrderService } from 'src/app/core/services/order.service';
import {
  GetOrder,
  ResetOrderState,
} from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent {
  @ViewChild('iframe', { read: ElementRef, static: false }) iframe:
    | ElementRef
    | undefined;

  order?: OrderDTO;
  invoice?: PaymentInvoiceDTO;
  hasUnfinishedPayments = false;
  cancelledBookings = false;

  /* email */
  emailSent = false;
  emailPaymentMsg?: string;
  emailRefundMsg?: string;
  emailConfirmationMsg?: string;
  emailConfirmationSent = false;
  loadingPaymentEmail = false;
  loadingRefundEmail = false;
  loadingConfirmationEmail = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private dateService: DateService,
    private defaultService: DefaultService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private orderService: OrderService
  ) {
    /* Only reset order state if navigation to something other than checkout or order */
    router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          !event.url.includes('hantera-betalning') &&
          !event.url.includes('order')
        ) {
          this.store.dispatch(new ResetOrderState());
        }
      }
    });
  }

  ngOnInit() {
    if (this.order === undefined) {
      const id = this.router.url.split('/')[2];
      this.getOrder(+id);
    } else this.getOrder();

    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        this.order = order;
        this.invoice = this.order?.payments?.find(
          (payment) => payment.paymentInvoice && payment.paymentInvoice
        )?.paymentInvoice;

        /* Check if all bookings are cancelled */
        this.cancelledBookings = this.order?.orderItems
          ? this.orderService.allOrderBookingsCancelled(this.order.orderItems)
          : false;
      });
  }

  ngOnChanges() {
    this.cancelledBookings = this.order?.orderItems
      ? this.orderService.allOrderBookingsCancelled(this.order.orderItems)
      : false;
  }

  getOrder(urlId?: number) {
    let id = urlId || this.order?.orderId;
    id !== undefined &&
      this.store
        .dispatch(new GetOrder(id))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          id !== undefined && // TS does not remember that this is not undefined
            this.defaultService
              .getUnfinishedPayments(id)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (response: PaymentDTO[]) =>
                  (this.hasUnfinishedPayments = response.length > 0)
              );
        });
  }

  formatDate(date?: Date): Date | string | undefined {
    return date && this.dateService.formatDate(date);
  }

  formatTime(date?: Date): Date | string | undefined {
    return date && this.dateService.formatTime(date);
  }

  awaitReceiptChanges(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.cdr.detectChanges();
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }

  printReceipt(paymentId?: number) {
    paymentId &&
      this.defaultService
        .getPaymentReceiptHtmlAdmin(paymentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (v) => {
            if (v.html) {
              if (this.iframe) {
                this.iframe.nativeElement.contentWindow.document.open();
                this.iframe.nativeElement.contentWindow.document.close();
                this.iframe.nativeElement.contentWindow.frames.document.body.insertAdjacentHTML(
                  'afterbegin',
                  v.html
                );
                this.awaitReceiptChanges().then(() => {
                  this.iframe?.nativeElement.contentWindow.print();
                });
              }
            }
          },
          error: (error) => {
            console.error('Error fetching receipt: ', error);

            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Det gick inte att skriva ut kvittot',
                message: ['Kvittot kunde inte hittas i kassasystemet.'],
              },
            });
          },
        });
  }

  goToCheckout() {
    this.router.navigate(['hantera-betalning']);
  }

  hasPaymentType(type: 'KLARNA' | 'INVOICE'): boolean {
    return this.order?.payments
      ? this.order.payments?.some(
          (payment) => payment.paymentMethod?.paymentMethodId === type
        )
      : false;
  }

  sendKlarnaReceipt(payment?: PaymentDTO) {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill skicka en Klarna-kvittokopia till kunden?`,
        message: [
          `Kunden kommer att få en Klarna-kvittokopia till den angivna e-postadressen i bokningen.`,
        ],
        yesBtnText: 'Skicka',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.emailSent = true;

          /* Set correct loading icon */
          if (payment && payment.transactionType === 'PAYMENT') {
            this.loadingPaymentEmail = true;
            this.loadingRefundEmail = false;
          } else if (payment) {
            this.loadingPaymentEmail = false;
            this.loadingRefundEmail = true;
          }

          /* Finished loading */
          this.emailPaymentMsg = undefined;
          this.emailRefundMsg = undefined;

          payment?.paymentId &&
            this.defaultService
              .sendPaymentReceiptAdmin(payment.paymentId)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe({
                next: () => {
                  this.emailSent = true;

                  /* Set correct email message */
                  if (payment.transactionType === 'PAYMENT') {
                    this.emailPaymentMsg = 'Kvitto skickat!';
                    this.emailRefundMsg = undefined;
                  } else {
                    this.emailPaymentMsg = undefined;
                    this.emailRefundMsg = 'Kvitto skickat!';
                  }

                  /* Finished loading */
                  this.loadingPaymentEmail = false;
                  this.loadingRefundEmail = false;
                },
                error: () => {
                  this.emailSent = false;

                  /* Set correct email message */
                  if (payment.transactionType === 'PAYMENT') {
                    this.emailPaymentMsg =
                      'Det gick inte att skicka mejlet, kontrollera e-postadressen.';
                    this.emailRefundMsg = undefined;
                  } else {
                    this.emailPaymentMsg = undefined;
                    this.emailRefundMsg =
                      'Det gick inte att skicka mejlet, kontrollera e-postadressen.';
                  }

                  /* Finished loading */
                  this.loadingPaymentEmail = false;
                  this.loadingRefundEmail = false;
                },
              });
        }
      });
  }

  sendConfirmationEmail() {
    const name = this.order?.orderItems?.find(
      (item) => item.orderItemType === 'RESOURCE'
    )?.booking?.name;

    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill skicka orderbekräftelse till ${
          name || 'okänd'
        }?`,
        message: [
          `Kunden kommer att få en orderbekräftelse till den angivna e-postadressen.`,
        ],
        yesBtnText: 'Skicka',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          if (this.order?.orderId) {
            this.loadingConfirmationEmail = true;
            this.emailConfirmationMsg = undefined;

            this.defaultService
              .sendOrderConfirmationAdmin(this.order?.orderId)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe({
                next: () => {
                  this.emailConfirmationSent = true;
                  this.emailConfirmationMsg = 'Orderbekräftelse skickad!';
                  this.loadingConfirmationEmail = false;
                },
                error: () => {
                  this.emailConfirmationSent = false;
                  this.emailConfirmationMsg =
                    'Det gick inte att skicka orderbekräftelsen, kontrollera e-postadressen.';
                  this.loadingConfirmationEmail = false;
                },
              });
          } else {
            this.emailConfirmationSent = false;
            this.emailConfirmationMsg =
              'Det gick inte att skicka orderbekräftelsen, ladda om sidan och försök igen.';
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
