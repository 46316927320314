import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReportTravelerDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { GetReport } from 'src/app/core/states/reports/reports.actions';
import { ReportsState } from 'src/app/core/states/reports/reports.state';
dayjs.extend(utc);

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  selectedFromDate: Date = dayjs().utc().toDate();
  selectedToDate: Date = dayjs().utc().toDate();

  reports: ReportTravelerDTO[] = [];
  loading = true;

  private onDestroy$ = new Subject<void>();

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.formatDates();
  }

  formatDates() {
    this.selectedFromDate = dayjs(this.selectedFromDate).utc().toDate();
    this.selectedFromDate.setHours(0, 0, 0, 0);
    this.selectedToDate = new Date(this.selectedFromDate);
    this.selectedToDate.setHours(23, 59, 59, 0);
    this.getReports();
  }

  getReports() {
    this.loading = true;
    this.store.dispatch(new GetReport(this.selectedFromDate, this.selectedToDate)).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.store.selectOnce(ReportsState.currentReports).pipe(takeUntil(this.onDestroy$)).subscribe((reports) => {
        this.reports = reports;
        this.loading = false;
      });
    });
  }

  goNextDate() {
    this.selectedFromDate = dayjs(this.selectedFromDate).add(1, 'day').toDate();
    this.selectedToDate = dayjs(this.selectedToDate).add(1, 'day').toDate();
    this.getReports();
  }

  goPrevDate() {
    this.selectedFromDate = dayjs(this.selectedFromDate).subtract(1, 'day').toDate();
    this.selectedToDate = dayjs(this.selectedToDate).subtract(1, 'day').toDate();
    this.getReports();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
